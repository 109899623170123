import { defineNuxtRouteMiddleware, useAsyncData } from '#imports';
import { useLayoutStore } from '~dsc/stores/cms/layout';
import { usePageStore } from '~dsc/stores/cms/page';
import { abortNavigation } from '#app';

export default defineNuxtRouteMiddleware(async to => {
    try {
        const status = await useAsyncData('layout', () => useLayoutStore().layoutLoad());

        if (status.error.value) {
            throw Error(status.error.value);
        }
    } catch (e) {
        abortNavigation(e);
    }

    try {
        const status = await useAsyncData(`page-${to.fullPath}`, () => usePageStore().pageLoad(to));

        if (status.error.value) {
            throw Error(status.error.value);
        }
    } catch (e) {
        abortNavigation(e);
    }
});
