import { defineNuxtRouteMiddleware, navigateTo, useRequestURL, useNuxtApp } from '#imports';
import { httpStatus } from '~dsc/utils/http-status';

const redirects = {
    'fi-FI': {
        '/yksityisille': '/',
        '/private-ukk': '/yksityisille/tuotteet',
        '/ota-yhteytta/tilaa-lammitysoljya': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/yrityksille/ota-yhteytta/asiakaspalvelu/usein-kysyttya': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/1vision-biogasin-tavoitteena-tulla-pohjoismaiden-johtavaksi-biokaasutoimijaksi': '/tietoa-meista',
        '/1vision-biogasista-tulee-st1-biokraft': '/tietoa-meista',
        '/ajankohtaista-geolammosta': '/tietoa-meista',
        '/ajankohtaista-ja-tiedotteet': '/tietoa-meista',
        '/ajankohtaista-tietoa-polttonesteiden-hankintaketjustamme': '/tietoa-meista',
        '/ammattiliikenne': '/yrityksille',
        '/Aneo-HitecVision-and-St1-join-forces': '/tietoa-meista',
        '/apple-pay-maksusovellus-st1-asiakkaille': '/yksityisille/kanta-asiakkuus',
        '/arvonnan_saannot': '/',
        '/arvonnan-saannot-voita-sahkoauto': '/',
        '/arvonta_hiihtopipot': '/',
        '/arvonta_HS_lahjakortti': '/',
        '/asemahaku/asemahaku/st1-automaatti-hanko-kauppatori': '/asemahaku',
        '/asemahaku/shell-aanekoski-hirvaskangas': '/asemahaku',
        '/asemahaku/shell-aura': '/asemahaku',
        '/asemahaku/shell-espoo-bembole': '/asemahaku',
        '/asemahaku/shell-espoo-haukilahti': '/asemahaku',
        '/asemahaku/shell-espoo-lahnus': '/asemahaku',
        '/asemahaku/shell-espoo-suomenoja': '/asemahaku',
        '/asemahaku/shell-espoo-vallikallio': '/asemahaku',
        '/asemahaku/shell-express-helsinki-etela-haaga': '/asemahaku',
        '/asemahaku/shell-express-helsinki-kruununhaka': '/asemahaku',
        '/asemahaku/shell-express-hyvinkaa-sillankorvankatu': '/asemahaku',
        '/asemahaku/shell-express-kouvola-kappelikatu': '/asemahaku',
        '/asemahaku/shell-express-mikkeli-visulahti': '/asemahaku',
        '/asemahaku/shell-express-nurmijarvi-klaukkala': '/asemahaku',
        '/asemahaku/shell-express-oulu-valivainio': '/asemahaku',
        '/asemahaku/shell-express-tuusula-kellokoski': '/asemahaku',
        '/asemahaku/shell-express-vantaa-malmarinrinne': '/asemahaku',
        '/asemahaku/shell-express-vantaa-rajatorppa': '/asemahaku',
        '/asemahaku/shell-express-vantaa-rekola': '/asemahaku',
        '/asemahaku/shell-finstrom-godby': '/asemahaku',
        '/asemahaku/shell-hameenlinna-iittala': '/asemahaku',
        '/asemahaku/shell-helsinki-ala-tikkurila': '/asemahaku',
        '/asemahaku/shell-helsinki-alppila': '/asemahaku',
        '/asemahaku/shell-helsinki-etela-haaga': '/asemahaku',
        '/asemahaku/shell-helsinki-herttoniemi': '/asemahaku',
        '/asemahaku/shell-helsinki-itakeskus': '/asemahaku',
        '/asemahaku/shell-helsinki-kapyla': '/asemahaku',
        '/asemahaku/shell-helsinki-konala': '/asemahaku',
        '/asemahaku/shell-helsinki-lauttasaari': '/asemahaku',
        '/asemahaku/shell-helsinki-myllypuro': '/asemahaku',
        '/asemahaku/shell-helsinki-pukinmaki': '/asemahaku',
        '/asemahaku/shell-helsinki-tullinpuomi': '/asemahaku',
        '/asemahaku/shell-ii': '/asemahaku',
        '/asemahaku/shell-jamsa': '/asemahaku',
        '/asemahaku/shell-jarvenpaa-mannilantie': '/asemahaku',
        '/asemahaku/shell-jyvaskyla-matkakeskus': '/asemahaku',
        '/asemahaku/shell-kaarina': '/asemahaku',
        '/asemahaku/shell-kalajoki': '/asemahaku',
        '/asemahaku/shell-kankaanpaa': '/asemahaku',
        '/asemahaku/shell-karigasniemi': '/asemahaku',
        '/asemahaku/shell-kempele-zeppelin': '/asemahaku',
        '/asemahaku/shell-kokkola-vitsari': '/asemahaku',
        '/asemahaku/shell-lahti-kivistonmaki': '/asemahaku',
        '/asemahaku/shell-laitila': '/asemahaku',
        '/asemahaku/shell-lieto': '/asemahaku',
        '/asemahaku/shell-liperi-ylamylly': '/asemahaku',
        '/asemahaku/shell-lohja-virkkala': '/asemahaku',
        '/asemahaku/shell-loimaa': '/asemahaku',
        '/asemahaku/shell-maarianhamina': '/asemahaku',
        '/asemahaku/shell-maarianhamina-helmisimpukkaexpress': '/asemahaku',
        '/asemahaku/shell-mantsala-p': '/asemahaku',
        '/asemahaku/shell-mikkeli-ristiinantie': '/asemahaku',
        '/asemahaku/shell-muurame': '/asemahaku',
        '/asemahaku/shell-nurmijarvi-rajamaki': '/asemahaku',
        '/asemahaku/shell-oripaa': '/asemahaku',
        '/asemahaku/shell-orivesi': '/asemahaku',
        '/asemahaku/shell-oulu-haapalehto': '/asemahaku',
        '/asemahaku/shell-oulu-oulunsalo': '/asemahaku',
        '/asemahaku/shell-outokumpu': '/asemahaku',
        '/asemahaku/shell-parkano': '/asemahaku',
        '/asemahaku/shell-pihtipudas': '/asemahaku',
        '/asemahaku/shell-piikkio': '/asemahaku',
        '/asemahaku/shell-pori-koivula': '/asemahaku',
        '/asemahaku/shell-pori-noormarkku': '/asemahaku',
        '/asemahaku/shell-porvoo-kuninkaanportti': '/asemahaku',
        '/asemahaku/shell-raasepori-karjaa': '/asemahaku',
        '/asemahaku/shell-rovaniemi-erottaja': '/asemahaku',
        '/asemahaku/shell-rovaniemi-napapiiri': '/asemahaku',
        '/asemahaku/shell-rovaniemi-teollisuustie': '/asemahaku',
        '/asemahaku/shell-salo-halikko': '/asemahaku',
        '/asemahaku/shell-savonlinna-miekkoniemi': '/asemahaku',
        '/asemahaku/shell-siilinjarvi': '/asemahaku',
        '/asemahaku/shell-tampere-lahdenperankatu': '/asemahaku',
        '/asemahaku/shell-tampere-sammon-valtatie': '/asemahaku',
        '/asemahaku/shell-ts-helsinki-vuosaari-2': '/asemahaku',
        '/asemahaku/shell-ts-kotka-mussalo': '/asemahaku',
        '/asemahaku/shell-ts-nastola': '/asemahaku',
        '/asemahaku/shell-ts-rauma-satama': '/asemahaku',
        '/asemahaku/shell-ts-seinajoki-pohjankaari': '/asemahaku',
        '/asemahaku/shell-ts-tornio-varikonkatu': '/asemahaku',
        '/asemahaku/shell-ts-tuusula-senkkerin-metsatie': '/asemahaku',
        '/asemahaku/shell-turku-oriketo': '/asemahaku',
        '/asemahaku/shell-turku-satashell': '/asemahaku',
        '/asemahaku/shell-vaasa-pitkakatu': '/asemahaku',
        '/asemahaku/shell-vammala-pesurinkatu': '/asemahaku',
        '/asemahaku/shell-vammala-stormi': '/asemahaku',
        '/asemahaku/shell-vantaa-hakunila': '/asemahaku',
        '/asemahaku/shell-vantaa-kaivoksela': '/asemahaku',
        '/asemahaku/shell-vantaa-mikkola': '/asemahaku',
        '/asemahaku/shell-vantaa-ruskeasanta': '/asemahaku',
        '/asemahaku/shell-veneasema-helsinki-karhusaari': '/asemahaku',
        '/asemahaku/shell-ylivieska': '/asemahaku',
        '/asemahaku/shell-ylojarvi': '/asemahaku',
        '/asemahaku/st1-aura': '/asemahaku',
        '/asemahaku/st1-automaatti-espoo-auroranportti': '/asemahaku',
        '/asemahaku/st1-automaatti-espoo-lahderanta': '/asemahaku',
        '/asemahaku/st1-automaatti-espoo-olarinluoma': '/asemahaku',
        '/asemahaku/st1-automaatti-heinola': '/asemahaku',
        '/asemahaku/st1-automaatti-helsinki-kivikko': '/asemahaku',
        '/asemahaku/st1-automaatti-helsinki-tattarisuo': '/asemahaku',
        '/asemahaku/st1-automaatti-hollola-kunnantie': '/asemahaku',
        '/asemahaku/st1-automaatti-hyvinkaa-hakakallio': '/asemahaku',
        '/asemahaku/st1-automaatti-iitti-kausala': '/asemahaku',
        '/asemahaku/st1-automaatti-ilmajoki': '/asemahaku',
        '/asemahaku/st1-automaatti-jamsa-patalahti': '/asemahaku',
        '/asemahaku/st1-automaatti-joensuu-reijola': '/asemahaku',
        '/asemahaku/st1-automaatti-jyvaskyla-seppala': '/asemahaku',
        '/asemahaku/st1-automaatti-jyvaskyla-viitaniemi': '/asemahaku',
        '/asemahaku/st1-automaatti-kaarina': '/asemahaku',
        '/asemahaku/st1-automaatti-kajaani-sissikatu': '/asemahaku',
        '/asemahaku/st1-automaatti-kalvia': '/asemahaku',
        '/asemahaku/st1-automaatti-kangasala-kt-921': '/asemahaku',
        '/asemahaku/st1-automaatti-kangasniemi': '/asemahaku',
        '/asemahaku/st1-automaatti-kerava-keskusta': '/asemahaku',
        '/asemahaku/st1-automaatti-kirkkonummi-veikkola': '/asemahaku',
        '/asemahaku/st1-automaatti-kontiolahti': '/asemahaku',
        '/asemahaku/st1-automaatti-kouvola-kankaro': '/asemahaku',
        '/asemahaku/st1-automaatti-kuopio-petonen': '/asemahaku',
        '/asemahaku/st1-automaatti-kuopio-saaristokatu': '/asemahaku',
        '/asemahaku/st1-automaatti-lahti-renkomaki': '/asemahaku',
        '/asemahaku/st1-automaatti-lapua-keskusta': '/asemahaku',
        '/asemahaku/st1-automaatti-lapua-poutuntie': '/asemahaku',
        '/asemahaku/st1-automaatti-lieksa': '/asemahaku',
        '/asemahaku/st1-automaatti-mantsala-keskusta': '/asemahaku',
        '/asemahaku/st1-automaatti-mikkeli-insinoorinkatu': '/asemahaku',
        '/asemahaku/st1-automaatti-mikkeli-mannerheimintie': '/asemahaku',
        '/asemahaku/st1-automaatti-nakkila': '/asemahaku',
        '/asemahaku/st1-automaatti-nokia-nuijamiestentie': '/asemahaku',
        '/asemahaku/st1-automaatti-nokia-yrittajakatu': '/asemahaku',
        '/asemahaku/st1-automaatti-nurmes': '/asemahaku',
        '/asemahaku/st1-automaatti-orivesi': '/asemahaku',
        '/asemahaku/st1-automaatti-oulu-automuseontie': '/asemahaku',
        '/asemahaku/st1-automaatti-oulu-knuutilankangas': '/asemahaku',
        '/asemahaku/st1-automaatti-outokumpu': '/asemahaku',
        '/asemahaku/st1-automaatti-petajavesi': '/asemahaku',
        '/asemahaku/st1-automaatti-pirkkala': '/asemahaku',
        '/asemahaku/st1-automaatti-pori-mikkola': '/asemahaku',
        '/asemahaku/st1-automaatti-pori-musa': '/asemahaku',
        '/asemahaku/st1-automaatti-pornainen': '/asemahaku',
        '/asemahaku/st1-automaatti-pudasjarvi': '/asemahaku',
        '/asemahaku/st1-automaatti-raasepori-tammisaari': '/asemahaku',
        '/asemahaku/st1-automaatti-raisio-hauninen': '/asemahaku',
        '/asemahaku/st1-automaatti-raisio-kuninkoja': '/asemahaku',
        '/asemahaku/st1-automaatti-salo-turuntie': '/asemahaku',
        '/asemahaku/st1-automaatti-sastamala-vammala': '/asemahaku',
        '/asemahaku/st1-automaatti-sipoo-jokivarrentie': '/asemahaku',
        '/asemahaku/st1-automaatti-sotkamo': '/asemahaku',
        '/asemahaku/st1-automaatti-suomussalmi-ammansaari': '/asemahaku',
        '/asemahaku/st1-automaatti-tampere-lielahti': '/asemahaku',
        '/asemahaku/st1-automaatti-turku-moisio': '/asemahaku',
        '/asemahaku/st1-automaatti-tuusula-kulomaentie': '/asemahaku',
        '/asemahaku/st1-automaatti-tuusula-riihikallio': '/asemahaku',
        '/asemahaku/st1-automaatti-ulvila': '/asemahaku',
        '/asemahaku/st1-automaatti-vantaa-korso': '/asemahaku',
        '/asemahaku/st1-automaatti-vantaa-porttisuo': '/asemahaku',
        '/asemahaku/st1-automaatti-vantaa-simonkyla': '/asemahaku',
        '/asemahaku/st1-automaatti-vantaa-vapaala': '/asemahaku',
        '/asemahaku/st1-automaatti-vantaa-virkatie': '/asemahaku',
        '/asemahaku/st1-automaatti-vantaa-voutila': '/asemahaku',
        '/asemahaku/st1-automaatti-ylane': '/asemahaku',
        '/asemahaku/st1-automaatti-ylistaro': '/asemahaku',
        '/asemahaku/st1-biogas-salo-halikko': '/asemahaku',
        '/asemahaku/st1-espoo-bembole': '/asemahaku',
        '/asemahaku/st1-eura': '/asemahaku',
        '/asemahaku/st1-ev-mantsala-p': '/asemahaku',
        '/asemahaku/st1-honkajoki': '/asemahaku',
        '/asemahaku/st1-ikaalinen': '/asemahaku',
        '/asemahaku/st1-kangasala-kt-1070': '/asemahaku',
        '/asemahaku/st1-kankaanpaa': '/asemahaku',
        '/asemahaku/st1-karvia': '/asemahaku',
        '/asemahaku/st1-kerimaki': '/asemahaku',
        '/asemahaku/st1-kouvola-myllykoski': '/asemahaku',
        '/asemahaku/st1-lappeenranta-hakali': '/asemahaku',
        '/asemahaku/st1-lappeenranta-lauritsala': '/asemahaku',
        '/asemahaku/st1-laukaa': '/asemahaku',
        '/asemahaku/st1-laukaa-vihtavuori': '/asemahaku',
        '/asemahaku/st1-lbg-hameenlinna-iittala': '/asemahaku',
        '/asemahaku/st1-lbg-mantsala-p': '/asemahaku',
        '/asemahaku/st1-liperi-kuusikkolantie': '/asemahaku',
        '/asemahaku/st1-lohja-virkkala': '/asemahaku',
        '/asemahaku/st1-mantsala-p': '/asemahaku',
        '/asemahaku/st1-naantali-alppilankatu': '/asemahaku',
        '/asemahaku/st1-oravainen': '/asemahaku',
        '/asemahaku/st1-pirkkala': '/asemahaku',
        '/asemahaku/st1-pori-porinportti': '/asemahaku',
        '/asemahaku/st1-raisio-keskusta': '/asemahaku',
        '/asemahaku/st1-raisio-kuninkoja': '/asemahaku',
        '/asemahaku/st1-tampere-sammon-valtatie': '/asemahaku',
        '/asemahaku/st1-vantaa-lentoasema': '/asemahaku',
        '/asemahaku/st1-veneasema-helsinki-lauttasaari': '/asemahaku',
        '/asematapahtumat': '/',
        '/asiakaspalvelu-korttikuoletukset-peruutukset': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/asiakaspalvelu-laskutus': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/asiakaspalvelu-lisakorttien-tilaukset': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/asiakaspalvelu-muu': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/asiakaspalvelu-nettipesu': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/asiakaspalvelu-osoitteen-muutos': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/asiakaspalvelu-st1-asemat': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/asiakaspalvelu-suoratoimitukset': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/asiakaspalvelu-tunnusluvun-tilaaminen': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/asiakaspalvelumme-on-ruuhkautunut2': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/asiakastiedote': '/',
        '/asiakkaan-aani': '/',
        '/autopesu-arvonnan-saannot': '/',
        '/b2b': '/yrityksille/b2b',
        '/bensiini': '/yksityisille/tuotteet',
        '/capman-infra-ja-st1-sopivat-yhteistyosta-maalampolaitosten-rakentamiseksi': '/tietoa-meista',
        '/caverion-ja-st1-lahienergia-yhdistavat-nyt-osaamisensa-suurkiinteistojen-maalampoprojekteissa': '/tietoa-meista',
        '/cellunolix-etanolitehtaan-rakentaminen-alkaa-kajaanissa': '/tietoa-meista',
        '/corporate.vattenfall.se': '/tietoa-meista',
        '/Covid-19-vaikutus-asemilla': '/',
        '/dataprivacy@st1.fi': '/',
        '/dataprivacy@st1.no': '/',
        '/dataprivacy@st1.se': '/',
        '/Davvin_tuulipuistohankkeen_sosiaalinen_vastuu_ja_vaikutusten_arviointi': '/tietoa-meista',
        '/Davvin-tuulivoimapuistohanketta-tehdaan-paikallisyhteisojen-kanssa': '/tietoa-meista',
        '/deepheat': '/yksityisille/tuotteet',
        '/demo-form': '/',
        '/diesel': '/yksityisille/tuotteet',
        '/dieselin2laatupisteet': '/yrityksille/energiatuotteet/b2b-dieselit/dieselin2laatupisteet',
        '/edullinen-etanoliautoilu-nyt-mahdollista-sadoille-tuhansille-bensiiniautoilijoil': '/yksityisille/tuotteet',
        '/ekoautoseminaari-eduskunta-tiivistelma': '/',
        '/en': 'https://st1.com',
        '/eng/privacy-policy': '/eng/privacy-policy',
        '/eng/st1-sahkoisten-kanavien-kayttoehdot-kuluttajille': '/Terms-of-Use-of-St1-Electronic-Channels-for-Consumers',
        '/eng/st1asiakasohjelmanehdot': '/',
        '/ensimmaiset-huoltamokahvilatyontekijat-valmistuivat-st1n-uudesta-koulutusohjelmasta': '/tietoa-meista',
        '/etanoliautoilijat-ry-toimii-vahapaastoisemman-liikenteen-puolesta': '/tietoa-meista',
        '/etanoliautoilu': '/',
        '/etumatkaajat': '/',
        '/etumatkakiertue-arvonnan-saannot': '/',
        '/fi/st1-stories-hallituksen-puheenjohtajan-katsaus-kun-on-tahtoa-myos-keinot-keksitaan': '/tietoa-meista',
        '/fi/st1-stories-merkittavia-raskaan-liikenteen-paastovahennyksia-voitaisiin-tehda-jo-nyt': '/tietoa-meista',
        '/fi/st1-stories-st1-rakentaa-kaksi-raskaan-liikenteen-biokaasun-tankkausasemaa-suomeen': '/tietoa-meista',
        '/geolampo': '/yksityisille/tuotteet',
        '/halytyspalvelu': '/',
        '/henrikki-talvitie-st1-oyn-uudeksi-toimitusjohtajaksi': '/tietoa-meista',
        '/hiihtoliittoyhteistyo': '/tietoa-meista/st1-yrityksena/hiihtoliitto',
        '/huijausviesteja-liikkeella': '/',
        '/huoltoasemakysely': '/',
        '/huoltoasemat-nyt-ja-tulevaisuudessa': '/',
        '/huoltoasemat': '/',
        '/hvo': '/yksityisille/tuotteet',
        '/hvodiesel': '/yksityisille/tuotteet',
        '/if-kunnon-koti-palvelu': '/',
        '/iin-olhavan-uudet-voimalat-nostavat-tuuliwatin-tuulivoimakapasiteetin-yli-200-mw': '/tietoa-meista',
        '/inferno': '/yrityksille/energiatuotteet/b2b-lammitysoljyt',
        '/jatkoa-asemauudistuksille-st1-kunnosti-lippulaiva-asemansa-shell-ruskeasannan-vantaalla': '/tietoa-meista',
        '/jo-90-helmisimpukkaa-ja-helmisimpukka-expressia-ympari-maata': '/tietoa-meista',
        '/kahdeksan-voimalan-tuulipuiston-rakentaminen-alkaa-siikaisissa': '/tietoa-meista',
        '/kajaanin-cellunolixr-etanolitehtaan-rakentaminen-etenee-aikataulussa': '/tietoa-meista',
        '/kajaanin-cellunolixr-laajennushanke-yva-kuulutukseen': '/tietoa-meista',
        '/kampanja': '/',
        '/kampanja/arvonnan-saannot-hiihtoloma': '/',
        '/kanta-asiakas': '/',
        '/katevaraus': '/yrityksille/b2b-ota-yhteytta',
        '/kati-ihamaki-valittiin-st1-nordicin-hallitukseen': '/tietoa-meista',
        '/kauppiaan-kutsumus': '/',
        '/Kehitamme_syvalampoteknologiaa_kallioperatutkimuksella': '/',
        '/kesasetelin-kautta-toihin': '/tietoa-meista',
        '/kespron-ja-st1n-yhteistyo-jatkuu-ja-laajenee': '/tietoa-meista',
        '/kirjallisen-menettelyn-lopputulos-ehdotettu-paatos-hyvaksytty': '/tietoa-meista',
        '/kitkiaisvaaran-helmi-ja-7-muuta-pyorittavat-lisaa-tuulisahkoa-meri-lappiin': '/tietoa-meista',
        '/konsernitiedote-01-12-2021': '/tietoa-meista',
        '/koronavirustilanteen-vaikutus-palvelumestarin-palveluihin': '/',
        '/korttiehdot': '/yrityksille/b2b-ota-yhteytta',
        '/kristine-vergli-grant-carlsen-st1-norjan-uudeksi-toimitusjohtajaksi': '/',
        '/kryptovaluuttaa-kaupataan-huijaustarkoituksissa-anttosen-nimissa': '/',
        '/kuittipyyntolomake': '/yrityksille/b2b-ota-yhteytta',
        '/Kutsu_St1_Nordic_Oy:n_yhtiokokoukseen': '/',
        '/Kutsu_St1_Nordic_Oy:n_ylimaaraiseen_yhtiokokoukseen': '/',
        '/kutsu-st1-nordic-oyn-varsinaiseen-yhtiokokoukseen': '/',
        '/kutsu-yhtiokokoukseen': '/',
        '/kutsu-ylimaaraiseen-yhtiokokoukseen': '/',
        '/Ladulle-tielta-St1n-hiihtotempaus-tukee-autoilijoiden-tiehyvinvointia': '/',
        '/lahienergia-yhteydenottopyynto': '/yrityksille/b2b-ota-yhteytta',
        '/lammitysoljyn_tilaaminen': '/yrityksille/b2b-ota-yhteytta',
        '/lataus': '/yksityisille/tuotteet',
        '/lea-rankinen-nimitetty-sustainability-communications-and-public-affairs-business-unit-lead-tehtavaan-st1-nordic-oyssa': '/',
        '/legendaarinen-jamsan-patalahti-siirtyy-kotimaisen-st1n-lipun-alle': '/',
        '/lut-wartsila-ja-st1-power-to-x-ratkaisut-tulee-nostaa-suomen-energia-ja-ilmastoratkaisujen-ytimeen': '/',
        '/maalampokartoitus': '/yksityisille/tuotteet',
        '/markkinoiden-ensimmainen-tyokonediesel-st1-mpo-plus-vahentaa-kustannuksia-ja-paastoja': '/',
        '/mc-etu': '/',
        '/median-kontaktihenkilot': '/',
        '/menneentalvenlumet': '/',
        '/mika-eerola-st1-refinery-abn-toimitusjohtajaksi': '/',
        '/mobiilitankkaa-sujuvammin-ympari-suomen-yli-400lla-st1-ja-shell-asemalla-lataa-st1-way-sovellus': '/yksityisille/kanta-asiakkuus',
        '/mobiilitankkaus': '/yksityisille/kanta-asiakkuus',
        '/muutos-st1-nordic-oy-n-tilinpaatostiedotteen-2014-julkistusajankohtaan': '/',
        '/nivoksen-biokaasun-tankkausasema-mantsalassa-siirtyy-st1n-omistukseen': '/',
        '/node/201': '/',
        '/node/204': '/',
        '/node/221': '/',
        '/norjalainen-smart-fuel-as-siirtyy-st1-nordic-oy-n-omistukseen': '/',
        '/norjan-kilpailuviranomainen-hylkasi-st1-norge-asn-ehdotetun-ostajan': '/',
        '/oikaisu-huhuihin-emme-harjoita-lentopolttonesteliiketoimintaa-suomessa': '/',
        '/olemme-mukana-porin-asuntomessuilla': '/',
        '/osalla_St1_asemista_hairioita_maksutapahtumien_varmennusten_peruutuksissa': '/',
        '/otaniemen-reikien-stimulointivaihe-etenee-suunnitellusti-st1n-tyomaalla': '/',
        '/palaute-ja-yhteydenottopyynnot-jarjestokortti': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/palaute-ja-yhteydenottopyynnot-st1kateiskortti': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/palautelomake-shell-yrityskortit': '/yrityksille/b2b-ota-yhteytta',
        '/palautelomake-yrityksille': '/yrityksille/b2b-ota-yhteytta',
        '/palvelumestari-konsepti-paattyy-suomessa': '/',
        '/peab-asfaltin-pilotissa-osa-bitumista-on-korvattu-St1n-biopohjaisella-ligniinilla': '/',
        '/perfect-autopesu-arvonnan-saannot': '/',
        '/pohjoismaiset_verkkosivustomme': '/',
        '/poraustoiden-jatkamista-valmistellaan-st1n-geolampotyomaalla-espoossa': '/',
        '/postin-lakko-vaikuttaa-kirjeiden-toimitusaikoihin': '/',
        '/private-etu': '/',
        '/puhtaan-energian-tekijaksi': '/',
        '/q-power-ja-st1-pilotoivat-synteettisen-polttoaineen-valmistusta-biojalostamon-hiilidioksidista': '/',
        '/raahen-sarvankankaan-tuulipuisto-taydentaa-tuuliwatin-mittavan-investointiohjelm': '/',
        '/RE85_kampanja_arvonnan_saannot': '/',
        '/releases': '/',
        '/sahkoliitto': '/',
        '/sakerhetsanvisning': '/',
        '/SAKlaisten-liittojen-lakko': '/',
        '/SAKlaisten-liittojen-lakkouhka': '/',
        '/salon-marynummella-pyorivat-nyt-suomen-tehokkaimmat-tuulivoimalat': '/',
        '/sampsa-Halinen-nimitetty-energy-trade-and-logistics-liiketoimintayksikon-johtajaksi-st1-nordic-oyssa': '/',
        '/sampsa-halinen-valittiin-st1-nordicin-hallitukseen': '/',
        '/sca-ja-st1-ovat-perustaneet-yhteisyrityksen-tuottamaan-ja-kehittamaan-nestemaisia-biopolttoaineita': '/',
        '/SCAn-ja-St1n-Biorefinery-Ostrandille-sopimus-EUn-innovaatioavustuksesta': '/',
        '/security-guidelines': '/',
        '/seitseman-huoltoasemaa-pirkanmaalla-siirtyy-st1n-ja-shellin-vareihin': '/',
        '/Shell-card-private-korttikuoletukset': '/yrityksille/b2b-ota-yhteytta',
        '/shell-laskutus': '/yrityksille/b2b-ota-yhteytta',
        '/shell-lisakorttien-tilaus': '/yrityksille/b2b-ota-yhteytta',
        '/shell-mantsalaan-burger-king-ravintola': '/',
        '/shell-osoitteenmuutos': '/yrityksille/b2b-ota-yhteytta',
        '/shell-tunnusluvun-tilaus': '/yrityksille/b2b-ota-yhteytta',
        '/shell-yrityskortin-peruutus': '/yrityksille/b2b-ota-yhteytta',
        '/shellfleethubhakemuslomake': '/yrityksille/b2b-ota-yhteytta',
        '/shift': '/',
        '/shift/asiakaspolku': '/',
        '/shift/kenian-metsitysprojekti': '/',
        '/shift/projektien-vastuullisuus': '/',
        '/skal': '/yrityksille/business-palvelut/skal-jasenyys',
        '/st1_korttipalvelut': '/',
        '/St1_Nordic_Oy:n_integroitu_vuosikertomus_2019_julkaistu': '/',
        '/st1-allekirjoittanut-aiesopimuksen-viken-skogin-ja-treklyngenin-kanssa-cellunolixr': '/',
        '/st1-aloittaa-geotermisten-syvalampokaivojen-porauksen-espoon-otaniemessa': '/',
        '/st1-aloitti-biokaasuliiketoiminnan-ruotsissa-yhtion-biokaasuhankkeet-etenevat-myos-norjassa-ja-suomessa': '/',
        '/st1-automaatti-seinajoki-kasperi': '/',
        '/St1-avaa-uuden-biokaasulaitoksen-Ruotsissa': '/',
        '/st1-avasi-biokaasun-tankkauspisteet-raskaalle-liikenteelle-iittalassa-ja-mantsalassa': '/',
        '/st1-brandaa-uudelleen-shell-asemaverkostonsa': '/',
        '/st1-charge': '/yksityisille/tuotteet/sahkoauton-lataus',
        '/st1-charge/customer-service': '/yksityisille/tuotteet/sahkoauton-lataus',
        '/st1-energiamarket-laajentaa-toimintaansa-poraukseen': '/tietoa-meista',
        '/st1-etenee-suunnitelmissaan-tuottaa-uusiutuvaa-dieselia-goteborgin-jalostamollaa': '/tietoa-meista',
        '/st1-geolampoprojekti-on-edennyt-suunnitelmien-mukaan': '/tietoa-meista',
        '/st1-group-oy-sulautuu-st1-nordic-oy': '/',
        '/st1-henkilostokortti': '/',
        '/St1-investoi-aurinkoenergiaan': '/tietoa-meista',
        '/st1-investoi-biokaasuliiketoimintaan-ruotsissa': '/tietoa-meista',
        '/st1-ja-caverion-laajentavat-yhteistyotaan': '/tietoa-meista',
        '/st1-ja-compensate-yhteistyohon-liikenteen-hiilijalanjaljen-kompensoinnissa': '/tietoa-meista',
        '/st1-ja-fortum-aloittavat-geotermisen-lammontuotannon-pilottihankkeen-espoossa': '/tietoa-meista',
        '/st1-ja-horisont-energi-yhteistyohon-vihrean-ammoniakin-tuotannossa-finnmarkissa-norjassa': '/tietoa-meista',
        '/st1-ja-ivt-aloittavat-yhteistyon-uusiutuvan-energian-lammitysratkaisuissa': '/tietoa-meista',
        '/st1-ja-savikko-yhteistyossa': '/',
        '/st1-ja-sca-yhteistyohon-uusiutuvien-polttonesteiden-tuotannossa': '/tietoa-meista',
        '/st1-ja-sca-yhteisyritys-aloittaa-uusiutuvien-polttoaineiden-tuotannon-goteborgissa-ruotsissa': '/tietoa-meista',
        '/st1-ja-skal-yhteistyohon': '/',
        '/st1-ja-vtt-aloittavat-etanolituotannon-tutkimusprojektin': '/tietoa-meista',
        '/st1-kaynnistaa-kassavajatepohjaisen-edistyneen-etanolin-tuotantopilotin-thaimaassa': '/tietoa-meista',
        '/st1-kaynnistaa-maailman-syvimpien-geolampokaivojen-porauksen-viimeisen-vaiheen-otaniemessa': '/tietoa-meista',
        '/st1-kuluttajalammitysoljytilaukset-keskitetaan-tytaryhtiolle-lampopuisto-oylle': '/tietoa-meista',
        '/st1-kunnosti-karjaalla-yhden-suomen-suurimmista-shell-asemistaan': '/tietoa-meista',
        '/st1-kutsuu-koolle-energiapoliittisen-tulevaisuusfoorumin': '/tietoa-meista',
        '/st1-laajentaa-biokaasuliiketoimintaan-hankkimalla-eon-bioforin-ruotsissa': '/tietoa-meista',
        '/st1-laajentaa-biokaasuliiketoimintaan-norjassa': '/tietoa-meista',
        '/St1-laajentaa-sahkoautojen-suurteholatausverkostoaan': '/tietoa-meista',
        '/st1-laajentaa-toimintaansa-rahoitusalan-maksupalveluihin-ja-tuo-mobiilitankkauks': '/tietoa-meista',
        '/st1-lahienergia-palkittiin-ilmastotekona': '/tietoa-meista',
        '/st1-lahienergia-valittiin-vuoden-lahienergiaratkaisu-2017-kilpailun-voittajaksi': '/tietoa-meista',
        '/st1-lammitysoljyn-tilausportaalissa-otetaan-kayttoon-vahva-tunnistautuminen': '/tietoa-meista',
        '/st1-lanseeraa-karttasivuston-kotimaassa-autoileville': '/tietoa-meista',
        '/st1-mastercard': '/',
        '/st1-mastercard-korttimme-kaytto-venajalla-keskeytyy': '/',
        '/st1-mastercardin-ilme-muuttuu': '/',
        '/St1-mittausverkostossa-havaittiin-1': '/tietoa-meista',
        '/St1-mittausverkostossa-havaittiin-1,3-magnitudin-mikrojaristys': '/tietoa-meista',
        '/St1-mittausverkostossa-havaittiin-1,6-magnitudin-mikrojaristys': '/tietoa-meista',
        '/st1-mukaan-mahdollistamaan-tahdon-ymparistoaiheista-nayttelya': '/tietoa-meista',
        '/st1-myi-tuulivoimakapasiteettinsa-suomessa-exilion-tuulelle': '/tietoa-meista',
        '/st1-myy-nykyisen-tuulivoimakapasiteettinsa-suomessa-ja-laajentaa-tuulivoimatuotantoaan-pohjoismaihin': '/tietoa-meista',
        '/st1-n-geolammon-pilottihankkeen-luotausporaus-alkanut-espoossa': '/tietoa-meista',
        '/st1-n-geolampohanke-etenemassa-kohti-pilottilaitoksen-rakentamista': '/tietoa-meista',
        '/st1-n-geolampolaitoshanke-otaniemessa-etenee-aikataulussa': '/tietoa-meista',
        '/st1-n-geoterminen-pilottihanke-espoon-otaniemessa-etenee-stimulointivaiheeseen': '/tietoa-meista',
        '/st1-n-otaniemen-geotermisen-pilottihankkeen-poraukset-keskeytyvat-noin-puoleksi-': '/tietoa-meista',
        '/st1-nordic-energy-outlook-julkaistu': '/tietoa-meista',
        '/st1-nordic-oy-myy-st1-norge-asn-osakkeet': '/tietoa-meista',
        '/st1-nordic-oy-n-tilinpaatostiedote-2014': '/tietoa-meista',
        '/st1-nordic-oy-n-tilinpaatostiedote-2016': '/tietoa-meista',
        '/st1-nordic-oy-n-uudeksi-toimitusjohtajaksi-henrikki-talvitie': '/tietoa-meista',
        '/st1-nordic-oy-n-valitilinpaatostiedote-tammi-kesakuu-2017': '/tietoa-meista',
        '/st1-nordic-oy-ostaa-shellin-norjan-markkinointiyhtion-osakkeet': '/tietoa-meista',
        '/st1-nordic-oy-saanut-st1-norge-automat-as-n-osakkeiden-myynnin-paatokseen': '/tietoa-meista',
        '/st1-nordic-oy-sai-hyvaksynnan-st1-norge-automat-as-n-osakkeiden-myyntiin': '/tietoa-meista',
        '/st1-nordic-oy-tilinpaatostiedote-2018': '/tietoa-meista',
        '/st1-nordic-oy:n-osinnonjako': '/tietoa-meista',
        '/st1-nordic-oyn-tilinpaatostiedote-2015': '/tietoa-meista',
        '/st1-nordic-oyn-tilinpaatostiedote-2017': '/tietoa-meista',
        '/st1-nordic-oyn-tilinpaatostiedote-2021': '/tietoa-meista',
        '/st1-nordic-oyn-tilinpaatostiedote-2022': '/tietoa-meista',
        '/St1-Nordic-Oyn-tilinpaatostiedote-2023': '/tietoa-meista',
        '/st1-nordic-oyn-valitilinpaatostiedote-tammi--kesakuu-2022': '/tietoa-meista',
        '/st1-nordic-oyn-valitilinpaatostiedote-tammi-kesakuu-2016': '/tietoa-meista',
        '/st1-nordic-oyn-valitilinpaatostiedote-tammi-kesakuu-2018': '/tietoa-meista',
        '/st1-nordic-oyn-valitilinpaatostiedote-tammi-kesakuu-2019': '/tietoa-meista',
        '/st1-nordic-oyn-valitilinpaatostiedote-tammi-kesakuu-2020': '/tietoa-meista',
        '/st1-nordic-oyn-valitilinpaatostiedote-tammi-kesakuu-2021': '/tietoa-meista',
        '/st1-nordic-oyn-valitilinpaatostiedote-tammi-kesakuu-2023': '/tietoa-meista',
        '/st1-nordic-oyn-valitilinpaatostiedote-tammi-kesakuu-2024': '/tietoa-meista',
        '/st1-nordic-oyn-varsinainen-yhtiokokous-2020': '/tietoa-meista',
        '/st1-on-avannut-sahkoautojen-nopean-suurteholatausverkoston': '/tietoa-meista',
        '/st1-osallistuu-valtakunnalliseen-jaatyva-2017-harjoitukseen': '/tietoa-meista',
        '/st1-ostaa-meriliikenteen-polttonesteliiketoimintaa-norjassa': '/tietoa-meista',
        '/st1-oyn-varautuminen-koronaepidemian-aikana': '/tietoa-meista',
        '/st1-palkittiin-energy-globe-world-award-2019-gaalassa': '/tietoa-meista',
        '/st1-pilotoi-kestavaa-hiilensidontaa-metsittamalla-marokossa': '/tietoa-meista',
        '/st1-planerar-en-terminal-i-inga-for-att-oka-distributionen-av-fornybara-bransler': '/tietoa-meista',
        '/st1-rakensi-jatepohjaista-etanolia-tuottavan-etanolix-laitoksen-goteborgiin': '/tietoa-meista',
        '/st1-rakentaa-biojalostamon-uusiutuvan-dieselin-ja-lentopolttoaineen-tuottamiseksi': '/tietoa-meista',
        '/st1-rakentaa-biokaasun-jalostus--ja-nesteytyslaitoksen-ruotsiin': '/tietoa-meista',
        '/st1-rakentaa-jatepohjaisen-etanolin-tuotantolaitoksen-ruotsiin': '/tietoa-meista',
        '/st1-rakentamassa-raskaalle-liikenteelle-biokaasun-tankkauspisteita-suomessa': '/tietoa-meista',
        '/st1-sahkoisten-kanavien-kayttoehdot-kuluttajille': '/yksityisille/st1-sahkoisten-kanavien-kayttoehdot-kuluttajille',
        '/st1-sai-paatokseen-statoil-fuel-ja-retail-marine-asn-osakkeiden-kaupan-norjassa': '/tietoa-meista',
        '/st1-solmi-aiesopimuksen-bioetanolituotannon-pilotoinnista-thaimaassa': '/tietoa-meista',
        '/st1-stepone-tech-ja-motonet-yhteistyohon': '/tietoa-meista',
        '/st1-stories-globaalit-signaalit-antavat-syyta-optimismiin': '/tietoa-meista',
        '/st1-stories-goteborgin-jalostamo-kehittaa-turvallisuuskulttuuria-kayttaytymispohjaisen-ohjelman-avulla': '/tietoa-meista',
        '/st1-stories-hiiliviljely-osana-maatilojen-tuotantoa': '/tietoa-meista',
        '/st1-stories-investoinnit-epavakaassa-toimintaymparistossa': '/tietoa-meista',
        '/st1-stories-kestavan-kehityksen-kohokohdat-ja-haasteet': '/tietoa-meista',
        '/st1-stories-kohti-asfaltin-kestavampaa-hiilikiertoa': '/tietoa-meista',
        '/st1-stories-shell-sipoo-bastuksrissa-voi-tankata-myos-uusiutuvaa-hvo-dieselia': '/tietoa-meista',
        '/st1-stories-st1-suunnittelee-synteettisen-metanolin-pilottilaitosta-lappeenrantaan': '/tietoa-meista',
        '/st1-stories-st1-vahvistaa-arvoketjuaan-ostamalla-brocklesby-ltdn': '/tietoa-meista',
        '/st1-stories-st1n-sisainen-tekoalyavustaja-pluto': '/tietoa-meista',
        '/st1-stories-suomen-lantakaasu-oyn-tavoitteena-on-lisata-kotimaista-biokaasuntuotantoa': '/tietoa-meista',
        '/st1-stories-toimitusjohtajan-katsaus-haastava-toimintaymparisto': '/tietoa-meista',
        '/st1-stories-toimitusjohtajan-katsaus-valmiina-tulevaisuuteen': '/tietoa-meista',
        '/st1-stories-tutkimuksen-kohteena-power-to-x': '/tietoa-meista',
        '/st1-stories-valion-ja-st1n-yhteisyritys-valmiina-lisaamaan-kotimaista-biokaasuntuotantoa': '/tietoa-meista',
        '/st1-stories-vantaan-energia-ja-st1-yhteistyohon-sahkopolttoaineen-jakelussa': '/tietoa-meista',
        '/st1-stories-vastuullisuusjohtajan-katsaus-vuoden-2021-kohokohdat-ja-haasteet': '/tietoa-meista',
        '/st1-stories-vattenfall-ja-st1-yhteistyohon-lentoliikenteen-fossiilittoman-sahkopolttoaineen-mittavassa-tuotannossa': '/tietoa-meista',
        '/st1-stories/hiilidioksidin-poistopotentiaalin-tutkimista': '/tietoa-meista',
        '/st1-suunnittelee-inkooseen-terminaalia-uusiutuvien-polttonesteiden-jakelun-kasvattamiseksi': '/tietoa-meista',
        '/st1-suunnittelee-kehittyneiden-biopolttoaineiden-tuotannon-laajentamista-uusiutu': '/tietoa-meista',
        '/st1-suunnittelee-synteettisen-metanolin-pilottilaitosta-lappeenrantaan': '/tietoa-meista',
        '/st1-tarjoaa-asiakkailleen-tiehyvinvointia-ainutlaatuinen-hiihtomahdollisuus-valikoiduilla-asemilla': '/tietoa-meista',
        '/st1-teki-investointipaatoksen-geotermisen-pilottilampolaitoksen-rakentamisesta-espoon-otaniemeen': '/tietoa-meista',
        '/st1-teki-oman-rekrytointikanavan-huoltoasema-alalle': '/tietoa-meista',
        '/st1-tuo-myyntiin-uusiutuvan-hvo-diesel-dieselpolttoaineen': '/tietoa-meista',
        '/st1-uudisti-suomen-suurimman-shell-aseman-mantsalassa': '/tietoa-meista',
        '/st1-vahvistaa-jateraaka-aineliiketoimintaansa-ostamalla-brocklesby-ltdn-iso-britanniasta': '/tietoa-meista',
        '/st1-valittiin-leaders-of-sustainable-biofuels-koalition-puheenjohtajaksi': '/tietoa-meista',
        '/st1-way-sovelluksessa-paivitystoita': '/yksityisille/kanta-asiakkuus',
        '/st1-way-sovellus': '/yksityisille/kanta-asiakkuus',
        '/st1-way-sovellus/saasta-rahaa-sovelluksella': '/yksityisille/kanta-asiakkuus',
        '/st1-way-sovellus/tarkeimmat-palvelut': '/yksityisille/kanta-asiakkuus',
        '/st1-yhteistyohon-sfcaravanin-kanssa': '/',
        '/st1-yrityskortit-uusiutuvat': '/yrityksille/st1-business-kortti',
        '/st1-yrityskortti': '/yrityksille/st1-business-kortti',
        '/St1:n-geolampoprojektin-stimulointivaihe-onnistuneesti-paatokseen': '/tietoa-meista',
        '/St1:n-mittausverkostossa-havaittiin-1,2-magnitudin-mikrojaristys': '/tietoa-meista',
        '/st1.fi/asemahaku': '/asemahaku',
        '/st1business-maarajoitusten-muutos': '/yrityksille/b2b-ota-yhteytta',
        '/st1businesstarjouspyynto': '/yrityksille/st1-business-kortti',
        '/st1halytyspalvelu': '/',
        '/st1n_verkkoportaalien_yleiset_ehdot_asiakasyrityksille': '/yrityksille/st1n_verkkoportaalien_yleiset_ehdot_asiakasyrityksille',
        '/St1n-asemaverkoston-kolmas-raskaan-liikenteen-biokaasun-tankkauspiste-avautui-Salon-Halikkoon': '/tietoa-meista',
        '/st1n-etanolintuotanto-paattyy-vantaalla-lahdessa-ja-kajaanissa': '/tietoa-meista',
        '/St1n-geolampotyomaan-stimulointivaihe-tyollistaa-myos-opiskelijoita': '/tietoa-meista',
        '/st1n-geoterminen-pilottihanke-espoon-otaniemessa-etenee-lampokaivojen-viimeistelyvaiheeseen': '/tietoa-meista',
        '/St1n-geotermisilla-lampokaivoilla-Otaniemessa-aloitettiin-tutkimustyo': '/tietoa-meista',
        '/st1n-inkooseen-suunnitellun-terminaalihankkeen-ymparistovaikutusten-arivointiselostus-on-valmistunut': '/tietoa-meista',
        '/st1n-ja-sokn-yhteisyritys-neb-suunnittelee-50-miljoonan-litran-cellunolixr-bioetanolitehdasta': '/tietoa-meista',
        '/St1n-Lappeenrannan-P2X-hanke-ei-etene-alkuperaisessa-aikataulussa': '/tietoa-meista',
        '/st1n-marokon-metsityspilotin-tulokset-vahvistavat-hiilinieluja-voidaan-luoda-jopa-kuivissa-olosuhteissa': '/tietoa-meista',
        '/st1n-mittausverkostossa-havaittiin-1,67-magnitudin-mikrojaristys': '/tietoa-meista',
        '/St1n-Otaniemen_geotermisen_lampolaitoksen_pilottiprojekti_etenee_virtaustestivaiheeseen': '/tietoa-meista',
        '/St1n-Otaniemen-geolampotyomaan-stimulointivaihe-pyritaan-saamaan-valmiiksi-viikon-loppuun-mennessa': '/tietoa-meista',
        '/St1n-Otaniemen-geolampotyomaan-stimulointivaihe-pyritaan-saamaan-valmiiksi-viikon-loppuun-mennessa,-lue-hankekatsauspaivitys"': '/tietoa-meista',
        '/st1n-otaniemen-geotermisen-lampolaitoksen-pilottiprojektissa-selvitetaan-lammontuotantotapoja-': '/tietoa-meista',
        '/st1n-otaniemen-geotermisen-pilottiprojektin-syvat-lampokaivot-tutkimuskayttoon': '/tietoa-meista',
        '/stationlocator/map/index/query/shell-ts-seinajoki-roves': '/tietoa-meista',
        '/stiina.tuunanen@st1.com': '/',
        '/stimulointi-alkoi-geolampotyomaalla-otaniemessa': '/tietoa-meista',
        '/stimulointi-edennyt-otaniemessa-jo-yli-puolenvalin': '/tietoa-meista',
        '/Suomen-Lantakaasu-investoi-yli-80-miljoonaa-Kiuruveden-biokaasulaitoshankkeeseen': '/tietoa-meista',
        '/Suomen-Lantakaasu-ja-Atria-Suomi-investoivat-biokaasulaitokseen-Nurmossa': '/tietoa-meista',
        '/suomen-lantakaasu-oy-aloittaa-biokaasulaitoksen-maanrakennustyot-kiuruvedella': '/tietoa-meista',
        '/Suomen-Lantakaasu-Oy-edistaa-biokaasulaitosinvestointeja-Pohjois-Keski-ja-Etela-Pohjanmaalle': '/tietoa-meista',
        '/suomen-lantakaasu-oy-edistaa-kahden-uuden-biokaasulaitoksen-kehittamista-pohjanmaalla': '/tietoa-meista',
        '/Suomen-Lantakaasu-Oy-ostaa-Nurmon-Bioenergian': '/tietoa-meista',
        '/suomen-lantakaasu-oyn-biokaasulaitoksen-sijaintipaikasta-paatos-kiuruvedella-biokaasulaitosta-suunnitellaan-pyhasalmentien-etelapuolelle': '/tietoa-meista',
        '/suomen-suurin-tuulivoimapuisto-taydessa-tuotannossa-kalajoella': '/tietoa-meista',
        '/suomenhiihtoliiton-ja-st1n-valinen-historiallinen-kumppanuus-vie-kohti-seuraavaa-olympiadia': '/tietoa-meista',
        '/suoratoimitukset': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/sv/': '/',
        '/sv/anvandningen-av-st1-mastercard-kommer-att-forhindras-i-ryssland': '/',
        '/sv/dataprivacy@st1.fi': '/',
        '/sv/dataprivacy@st1.no': '/',
        '/sv/dataprivacy@st1.se': '/',
        '/sv/dataskyddsbeskrivning': '/dataskyddsbeskrivning',
        '/sv/hitta-station/': '/',
        '/sv/regler-for-formansresa-lotteriet': '/',
        '/sv/st1-investerar-i-biogas-i-sverige': '/',
        '/sv/St1-Mastercard-kreditkort-utseendet-fornyas': '/',
        '/sv/st1-sahkoisten-kanavien-kayttoehdot-kuluttajille': '/Anvandningsvillkor-for-St1-elektroniska-kanaler-for-konsumenter',
        '/sv/st1asiakasohjelmanehdot': '/',
        '/synteettisten-polttoaineiden-pilotlaitoksen-selvitystyo-joutsenoon-kaynnistyi': '/',
        '/talouspaallikko-st1-lahienergia': '/',
        '/tankkaa-naiselle-ammatti': '/',
        '/tarjoukset': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/tarjouspyynto': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/tarkeaa-tietoa-laskutuksesta': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/tarkeaa-tietoa-maksamisesta-ja-vahvasta-tunnistautumisesta-st1-way-mobiilisovelluksella': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/tee-oljylammityksen-automaattisopimus': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/teesi-08': '/',
        '/teesi-15': '/',
        '/teesi-19': '/',
        '/teesi-22': '/',
        '/teesi-36': '/',
        '/teesi-45': '/',
        '/teesi-46': '/',
        '/teesi-50': '/',
        '/teesi-75': '/',
        '/teesi-93': '/',
        '/teesi-95': '/',
        '/teollisuuden-lakoilla-ei-toistaiseksi-vaikutusta-st1-polttoainejakeluun': '/',
        '/test_page': '/',
        '/tiehyvinvointi': '/',
        '/tietosuoja': '/yksityisille/tietosuoja',
        '/tietosuoja/markkinointikaytannot': '/yksityisille/tietosuoja/markkinointikaytannot',
        '/tietosuoja/tapahtumat': '/yksityisille/tietosuoja',
        '/tietosuojaseloste': '/yksityisille/tietosuojaseloste',
        '/tietosuojaseloste_yritysasiakkaille': '/yrityksille/tietosuojaseloste_yritysasiakkaille',
        '/tilinpaatostiedote-2019': '/tietoa-meista',
        '/tilinpaatostiedote-2020': '/tietoa-meista',
        '/tom-rinne-nimitetty-group-hr-director-tehtavaan-st1-nordic-oyssa': '/tietoa-meista',
        '/truck': '/',
        '/turku-energia-hakee-toimenpidelupaa-geotermista-laitosta-varten-porattaville-lampokaivoille': '/',
        '/turku-energia-ja-st1-ovat-solmineet-aiesopimuksen-turkuun-rakennettavasta-geotermisesta-laitoksesta': '/',
        '/turvaohje': '/kayttoturva-ja-tuotetiedotteet',
        '/Tutkijat-kehittavat-metsityksenahiilensidonnan-kansainvalista-mittauskonseptia-ilmastonmuutoksen-hillitsemiseksi': '/',
        '/tutkimus-suomella-hyvat-mahdollisuudet-power-to-x-tuotantoon-perameren-alueella': '/',
        '/tutustu-paivitettyyn-tietosuojaselosteeseemme': '/yksityisille/tietosuoja',
        '/tuuliwatilta-suurinvestoinnit-simoon-raaheen-ja-kalajoelle': '/tietoa-meista',
        '/tuuliwatin-27-uutta-tuulivoimalaa-tuotannossa-simossa': '/tietoa-meista',
        '/tuuliwatin-tuulipuiston-avajaiset-tanaan-siikaisissa': '/tietoa-meista',
        '/tuuliwatti-aloittaa-uuden-strategiansa-toteutuksen-rakentamalla-markkinaehtoisesti-tuulipuiston-iin': '/tietoa-meista',
        '/TuuliWatti-nostaa-tuulisahkon-tuotannon-uudelle-tasolle': '/tietoa-meista',
        '/tuuliwatti-oli-kustannustehokkaimpia-uusiutuvan-energian-kilpailutuksessa': '/tietoa-meista',
        '/tuuliwatti-rakensi-markkinaehtoisen-tuulipuiston-iin-viinamakeen': '/tietoa-meista',
        '/tuuliwatti-rakentaa-suurtuulipuiston-kalajoelle': '/tietoa-meista',
        '/urakointi': '/',
        '/uramahdollisuudet': '/tietoa-meista/ihmiset-ja-ura/avoimet-tyopaikat',
        '/uudet-suuaukot-parantavat-korttiautomaattien-turvallisuutta': '/tietoa-meista',
        '/uudistunut-st1-diesel-plus-sisaltaa-upm-n-kotimaista-uusiutuvaa-dieselia': '/tietoa-meista',
        '/uusi-maksupalveludirektiivi-astuu-voimaan': '/tietoa-meista',
        '/uusi-shell-asema-avataan-vaasan-kivihaassa': '/tietoa-meista',
        '/uusi-shell-fuelsave-95-bensiini-vie-pidemmalle-kuin-perinteiset-95e-bensiinit': '/tietoa-meista',
        '/uusi-st1-asema-avattu-helsingin-kivikkoon': '/tietoa-meista',
        '/uutiskirje-luelisaa': '/',
        '/uutisoitu-polttoaineiden-jakelun-mahdollinen-keskeytyminen-ei-koske-st1-eika-shell-ketjuja': '/tietoa-meista',
        '/valio-ja-st1-selvittivat-yla-savon-maanviljelijoilla-laajaa-kiinnostusta-biokaasuntuotantoon-osallistumiseen': '/tietoa-meista',
        '/valio-ja-st1-tuottamaan-biokaasua-lannasta-uusi-pelinavaus-liikenteen-paastojen-pienentamiseksi': '/tietoa-meista',
        '/valion-ja-st1n-yhteisyritys-suomen-lantakaasu-oy-suunnittelee-biokaasulaitosinvestointia-lapinlahdelle-sonkajarvelle-ja-nurmekseen': '/tietoa-meista',
        '/valion-ja-st1n-yhteisyritys-suomen-lantakaasu-oy-valmiina-lisaamaan-kotimaista-biokaasuntuotantoa': '/tietoa-meista',
        '/valion-ja-st1n-yhteisyritys-suomen-lantakaasu-oyn-biokaasulaitoskokonaisuuden-suunnittelu-etenee': '/tietoa-meista',
        '/vantaan-energia-ja-st1-yhteistyohon-sahkopolttoaineen-jakelussa': '/tietoa-meista',
        '/vastuullisuusodotuksiamme-vuodelle-2021': '/tietoa-meista',
        '/vattenfall-ja-st1-yhteistyohon-lentoliikenteen-fossiilittoman-sahkopolttoaineen-mittavassa-tuotannossa-ruotsin-lansirannikolla': '/tietoa-meista',
        '/verkkokaupassa_toimintahairioita': '/',
        '/veronmaksajat': '/',
        '/vikatilanne': '/vika-ja-hairiotiedotteet',
        '/vtt-ja-st1-pilotoivat': '/tietoa-meista',
        '/vuoden-lahienergiaratkaisu-2017-kilpailu-kerasi-ison-joukon-osallistujia': '/tietoa-meista',
        '/wartsila': '/',
        '/wartsila,-tieto,-st1,-fortum-and-demos-helsinki-launch-innovation-community-initiative-to-speed-clean-energy-targets"': '/',
        '/www.karavaanarit.fi/jasenyys/muutokset-jasenyyteen': '/',
        '/yhteistyo-hiihtoliiton-kanssa-jatkuu': '/tietoa-meista',
        '/yksityisille/asemat/palvelut': '/yksityisille/palvelut',
        '/yksityisille/kirjaudu.st1.fi/?service=B2B': '/yrityksille/b2b-ota-yhteytta',
        '/yksityisille/kortit-ja-edut': '/yksityisille/kanta-asiakkuus',
        '/yksityisille/kortit-ja-edut/kortit/jasenkortti': '/yksityisille/kanta-asiakkuus',
        '/yksityisille/kortit-ja-edut/kortit/kateisalennuskortti': '/yksityisille/kanta-asiakkuus',
        '/yksityisille/kortit-ja-edut/kortit/shell-card-private': '/yksityisille/kanta-asiakkuus',
        '/yksityisille/kortit-ja-edut/kortit/st1-visa': '/yksityisille/kanta-asiakkuus',
        '/yksityisille/maksaminen-ja-edut': '/yksityisille/kanta-asiakkuus',
        '/yksityisille/maksaminen-ja-edut/kanta-asiakkuus': '/yksityisille/kanta-asiakkuus',
        '/yksityisille/maksaminen-ja-edut/kanta-asiakkuus/jarjestot?utm_source=web&utm_medium=post&utm_campaign=b2c_': '/yksityisille/kanta-asiakkuus',
        '/yksityisille/maksaminen-ja-edut/kanta-asiakkuus/kateisalennuskortti': '/yksityisille/kanta-asiakkuus',
        '/yksityisille/maksaminen-ja-edut/kanta-asiakkuus/shell-card-private': '/yksityisille/kanta-asiakkuus',
        '/yksityisille/ota-yhteytta': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/yksityisille/ota-yhteytta/asiakaspalvelu/anna-palautetta/asiakaspalvelu-helmisimpukka': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/yksityisille/ota-yhteytta/asiakaspalvelu/kayttoturva-ja-tuotetiedotteet-seka-sopimusehdot': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/yksityisille/ota-yhteytta/asiakaspalvelu/kirjaudu.st1.fi': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/yksityisille/ota-yhteytta/asiakaspalvelu/lammitysoljyn-myynti': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/yksityisille/ota-yhteytta/asiakaspalvelu/palvelulomakkeet': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/yksityisille/ota-yhteytta/asiakaspalvelu/usein-kysyttya': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/yksityisille/ota-yhteytta/media/ajankohtaista-ja-tiedotteet/henrikki-talvitie-valittiin-tuuliwatti': '/tietoa-meista',
        '/yksityisille/ota-yhteytta/media/ajankohtaista-ja-tiedotteet/tuuliwatti-rakentaa-jattiakun-viinamaen': '/tietoa-meista',
        '/yksityisille/ota-yhteytta/yhteystiedot/yhteyshenkilot-vastuualueittain': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        '/yksityisille/st1-yhtiona': '/tietoa-meista',
        '/yksityisille/tuotteet-ja-palvelut': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/lammitysoljy': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/lammitysoljy/st1-opti': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/lammitysoljy/teho-opti-premium': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/palvelut': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/palvelut/pikakassa': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/polttonesteet/bensiinit-ja-re85': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/polttonesteet/bensiinit-ja-re85/95-e10-extra': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/polttonesteet/bensiinit-ja-re85/98-e5': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/polttonesteet/bensiinit-ja-re85/re85': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/polttonesteet/dieselit-ja-adblue': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/polttonesteet/dieselit-ja-adblue/adblue': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/polttonesteet/dieselit-ja-adblue/diesel-plus': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/polttonesteet/dieselit-ja-adblue/hvodiesel': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/polttonesteet/moottoripolttooljyt': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/polttonesteet/moottoripolttooljyt/mpo-plus': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/polttonesteet/moottoripolttooljyt/st1-opti-moottoripolttooljy': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/polttonesteet/raskaat-polttooljyt': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/polttonesteklinikka': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/polttonesteklinikka/jalostus': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/polttonesteklinikka/turvallisuus-kuljetus-ja-varastointi': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/polttonesteklinikka/turvallisuus-kuljetus-ja-varastointi-0': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/polttonesteklinikka/turvallisuus-kuljetus-ja-varastointi/lammitysoljyn': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/polttonesteklinikka/velvoitteet-ja-standardit': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet-ja-palvelut/st1-lahienergia/referenssit-ja-asiakkaat/byggboom-kring': '/yrityksille/energiatuotteet/b2b-maalampo',
        '/yksityisille/tuotteet-ja-palvelut/st1-lahienergia/referenssit-ja-asiakkaat/case-aatunpiha-st1': '/yrityksille/energiatuotteet/b2b-maalampo',
        '/yksityisille/tuotteet-ja-palvelut/st1-lahienergia/referenssit-ja-asiakkaat/case-askolan-koulu-st1': '/yrityksille/energiatuotteet/b2b-maalampo',
        '/yksityisille/tuotteet-ja-palvelut/st1-lahienergia/referenssit-ja-asiakkaat/case-asuntosaatio': '/yrityksille/energiatuotteet/b2b-maalampo',
        '/yksityisille/tuotteet-ja-palvelut/st1-lahienergia/referenssit-ja-asiakkaat/case-jalmarinsalpa-st1': '/yrityksille/energiatuotteet/b2b-maalampo',
        '/yksityisille/tuotteet-ja-palvelut/st1-lahienergia/referenssit-ja-asiakkaat/case-niittykumpu-3-st1': '/yrityksille/energiatuotteet/b2b-maalampo',
        '/yksityisille/tuotteet-ja-palvelut/st1-lahienergia/referenssit-ja-asiakkaat/case-petaksenkulma-st1': '/yrityksille/energiatuotteet/b2b-maalampo',
        '/yksityisille/tuotteet-ja-palvelut/st1-lahienergia/referenssit-ja-asiakkaat/case-rakennus-salama': '/yrityksille/energiatuotteet/b2b-maalampo',
        '/yksityisille/tuotteet-ja-palvelut/st1-lahienergia/referenssit-ja-asiakkaat/case-sofia-st1': '/yrityksille/energiatuotteet/b2b-maalampo',
        '/yksityisille/tuotteet-ja-palvelut/tilaa-moottoripolttooljya': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet/lammitysoljy/inferno': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet/lammitysoljy/polttooljy': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet/lammitysoljy/roihu': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet/polttonesteet/bensiinit-ja-re85': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet/polttonesteet/dieselit-ja-adblue': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet/polttonesteet/moottoripolttooljyt': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet/polttonesteet/raskaat-polttooljyt': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet/polttonesteklinikka': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet/polttonesteklinikka/velvoitteet-ja-standardit': '/yksityisille/tuotteet',
        '/yksityisille/tuotteet/sahkolataus': '/yksityisille/tuotteet',
        '/yleinen-tietosuoja-asetus-voimaan': '/',
        '/yleisotilaisuus-espoon-otaniemessa-145-klo-1730': '/',
        '/ymparistolupa-st1-n-cellunolix-laitokselle-kajaaniin': '/',
        '/yrityksille/biokaasu': '/yrityksille/energiatuotteet/b2b-biokaasu',
        '/yrityksille/hae_st1_yrityskorttia': '/yrityksille/st1-business-kortti',
        '/yrityksille/inferno': '/yrityksille/energiatuotteet',
        '/yrityksille/infra-kumppanuus': '/yrityksille',
        '/yrityksille/kiitos': '/yrityksille',
        '/yrityksille/laskutus': '/yrityksille/b2b-ota-yhteytta',
        '/yrityksille/liuttu': '/yrityksille',
        '/yrityksille/moottoripolttooljy': '/yrityksille',
        '/yrityksille/open-shores-saimaa': '/yrityksille',
        '/yrityksille/ota-yhteytta': '/yrityksille/b2b-ota-yhteytta',
        '/yrityksille/ota-yhteytta/asiakaspalvelu/kayttoturva-ja-tuotetiedotteet-seka-sopimusehdot': '/yrityksille/b2b-ota-yhteytta',
        '/yrityksille/ota-yhteytta/asiakaspalvelu/kirjaudu.st1.fi': '/yrityksille/b2b-ota-yhteytta',
        '/yrityksille/ota-yhteytta/asiakaspalvelu/palvelulomakkeet': '/yrityksille/b2b-ota-yhteytta',
        '/yrityksille/ota-yhteytta/yhteystiedot/yhteyshenkilot-vastuualueittain': '/yrityksille/b2b-ota-yhteytta',
        '/yrityksille/palvelut': '/yrityksille/business-palvelut',
        '/yrityksille/palvelut/ammattikuljettajien-palvelut': '/yrityksille/business-palvelut',
        '/yrityksille/pirjon-pakari': '/yrityksille',
        '/yrityksille/polttooljyt': '/yrityksille/energiatuotteet/b2b-polttooljy',
        '/yrityksille/puhtaan-energian-tekijaksi': '/',
        '/yrityksille/ristimaa': '/yrityksille/energiatuotteet',
        '/yrityksille/roihu': '/yrityksille/energiatuotteet',
        '/yrityksille/s-kuljetus': '/yrityksille',
        '/yrityksille/shell_card/tarjouspyynto': '/yrityksille/b2b',
        '/yrityksille/shell_card/tilaa-elasku': '/yrityksille/b2b',
        '/yrityksille/shell-yrityskortit-sahkoinen-lasku-fleethub': '/yrityksille/b2b',
        '/yrityksille/st1_yrityskortin_uusinta': '/yrityksille/st1-business-kortti',
        '/yrityksille/st1-business': '/yrityksille/st1-business-kortti',
        '/yrityksille/st1-business?utm_source=seo&utm_medium=referral&utm_campaign=b2b_st1_business_etusivu': '/yrityksille/st1-business-kortti',
        '/yrityksille/st1-business/omat-sivut': '/yrityksille/business-palvelut/st1business',
        '/yrityksille/st1-business/palaute': '/yrityksille/business-palvelut/st1business',
        '/yrityksille/st1-business/sovellus': '/yrityksille/business-palvelut/st1business',
        '/yrityksille/st1-polttooljy': '/yrityksille/energiatuotteet',
        '/yrityksille/st1-stories': '/tietoa-meista',
        '/yrityksille/st1-yhtiona': '/tietoa-meista',
        '/yrityksille/st1businesskortti': '/yrityksille/st1-business-kortti',
        '/yrityksille/st1businesskortti/pin-koodi': '/yrityksille/business-palvelut/st1business',
        '/yrityksille/st1yritysportaali': '/yrityksille/business-palvelut/st1business',
        '/yrityksille/st1yritysportaali/kirjaudu': '/yrityksille/business-palvelut/st1business',
        '/yrityksille/st1yritysportaali/otakayttoon': '/yrityksille/business-palvelut/st1business',
        '/yrityksille/suoratoimitustilaus': '/yrityksille/energiatuotteet',
        '/yrityksille/teho-opti-premium': '/yrityksille/energiatuotteet',
        '/yrityksille/the-yellow-line': '/yrityksille',
        '/yrityksille/tilaa-elasku': '/yrityksille/b2b-ota-yhteytta',
        '/yrityksille/tilaa-sahkopostilasku': '/yrityksille/b2b-ota-yhteytta',
        '/yrityksille/tonttilan-nosto-ja-kuljetus': '/yrityksille',
        '/yrityksille/tuotteet': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/palvelut': '/yrityksille/business-palvelut',
        '/yrityksille/tuotteet-ja-palvelut/palvelut/ammattikuljettajien-palvelut': '/yrityksille/business-palvelut',
        '/yrityksille/tuotteet-ja-palvelut/palvelut/perfect-autopesut': '/yrityksille/business-palvelut',
        '/yrityksille/tuotteet-ja-palvelut/palvelut/pikakassa': '/yrityksille/business-palvelut',
        '/yrityksille/tuotteet-ja-palvelut/polttonesteet/bensiini': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/polttonesteet/bensiinit-ja-re85/95-e10-extra': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/polttonesteet/bensiinit-ja-re85/98-e5': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/polttonesteet/bensiinit-ja-re85/re85': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/polttonesteet/dieselit-ja-adblue': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/polttonesteet/dieselit-ja-adblue/adblue': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/polttonesteet/dieselit-ja-adblue/diesel-plus': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/polttonesteet/dieselit-ja-adblue/hvodiesel': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/polttonesteet/dieselit-ja-adblue/hvodiesel/asemaverkosto': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/polttonesteet/dieselit-ja-adblue/hvopolttooljy': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/polttonesteet/lammitysoljyt/st1-opti': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/polttonesteet/moottoripolttooljyt/mpo-plus': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/polttonesteet/moottoripolttooljyt/st1-opti-moottoripolttooljy': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/polttonesteet/raskaat-polttooljyt': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/polttonesteklinikka': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/polttonesteklinikka/jalostus': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/polttonesteklinikka/turvallisuus-kuljetus-ja-varastointi': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/polttonesteklinikka/turvallisuus-kuljetus-ja-varastointi-0': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/polttonesteklinikka/turvallisuus-kuljetus-ja-varastointi/oljyn': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/polttonesteklinikka/velvoitteet-ja-standardit': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/st1-lahienergia': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/st1-lahienergia/huoltotilaus': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/st1-lahienergia/referenssit': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/st1-lahienergia/st1-lahilampo': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet-ja-palvelut/st1-lahienergia/tietoa-maalammosta': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet/muut-polttonesteet/dieselit-ja-adblue': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet/polttonesteet/bensiinit-ja-re85': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet/polttonesteet/dieselit-ja-adblue': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet/polttonesteet/polttonesteklinikka': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet/polttooljyt/lammitysoljyt': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet/polttooljyt/moottoripolttooljyt': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet/polttooljyt/raskaat-polttooljyt': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet/polttooljyt/turvallisuus-kuljetus-ja-varastointi': '/yrityksille/energiatuotteet',
        '/yrityksille/tuotteet/st1-lahienergia': '/yrityksille/energiatuotteet',
        '/yrityksille/voiteluaineet': '/yrityksille/energiatuotteet',
        '/yrityksille/yhteistyossa': '/',
        '/yrityksille/yritysasiakkaan-uutiskirje': '/',
        '/yrityksille/yrityskortit-edut': '/yrityksille/st1-business-kortti',
        '/yrityksille/yrityskortit-edut/yrityskortit/shell-card': '/yrityksille/st1-business-kortti',
        '/yrityksille/yrityskortit-edut/yrityskortit/shell-card-dieselkortti': '/yrityksille/st1-business-kortti',
        '/yrityksille/yrityskortit-edut/yrityskortit/st1-d-kortti': '/yrityksille/st1-business-kortti',
        '/yrityksille/yrityskortit/yrityskortit/shell-card-dieselkortti': '/yrityksille/b2b',
        '/yrityksille/yrityskortit/yrityskortit/shell-cards': '/yrityksille/b2b',
        '/yrityksille/yritysmyynti': '/yrityksille/b2b-ota-yhteytta',
        '/yrityksille/yritysportaalin_palvelulomake': '/yrityksille/b2b-ota-yhteytta',
        '/yrityksille/yritysportaalin_palvelulomake?_gl=1*1mz8yf*_ga*MTIxNTM4NjM0MC4xNzIyNTA2Njcy*_ga_7TCTDSJKZR*MTcyNDY1Mzc2MC4zMS4xLjE3MjQ2NTM4NTguMC4wLjA': '/yrityksille/b2b-ota-yhteytta',
        '/yritysasiakkaan-uutiskirje': '/',
        '/yrityskorttikampanja': '/yrityksille/st1-business-kortti',
    },
    'en-EU': {
        '/about-us': '/',
        '/about-st1/nordic-energy-outlook': '/about-us/sustainability/energy-transition',
        '/fi/tuulivoima': 'https://st1.fi/tietoa-meista/vastuullisuus/energiatransitio/tuulivoima',
        '/st1-company-and-press-releases': '/about-us/news-and-media/company-and-press-releases',
        '/st1-mediabank': '/about-us/news-and-media',
        '/wind': '/about-us/sustainability/energy-transition/wind-power',
        '\'+String.fromCharCode(105,110,102,111,64,115,117,115,116,97,105,110,97,98,108,101,98,105,111,102,117,101,108,115,108,101,97,100,101,114,115,46,99,111,109)+\'?\'': '/',
        '\'+String.fromCharCode(108,105,105,115,97,46,106,111,101,110,112,111,108,118,105,64,115,116,49,46,102,105)+\'?\'': '/',
        '\'+String.fromCharCode(112,97,108,104,101,108,64,99,105,114,99,108,101,107,101,117,114,111,112,101,46,99,111,109)+\'?\'': '/',
        '\'+String.fromCharCode(116,105,105,110,97,46,118,101,104,109,97,108,97,45,118,105,107,115,116,101,110,64,115,111,107,46,102,105)+\'?\'': '/',
        '\'+String.fromCharCode(116,105,109,111,46,104,117,104,116,105,115,97,97,114,105,64,110,101,111,116,46,102,105)+\'?\'': '/',
        '\'+String.fromCharCode(66,106,111,114,110,46,65,107,101,114,108,117,110,100,64,97,107,114,97,102,116,46,102,105)+\'?\'': '/',
        '/1vision-biogas-is-set-to-become-a-leading-biogas-player-in-the-Nordics': 'https://www.sttinfo.fi/embedded/release/70471894/1vision-biogas-is-set-to-become-a-leading-biogas-player-in-the-nordics?publisherId=69820571&lang=en&widget=true',
        '/1Vision-biogas-received-necessary-regulatory-clearances': '/about-us/news-and-media/company-and-press-releases',
        '/1vision-biogas-will-change-its-name-to-st1-biokraft': '/about-us/news-and-media/company-and-press-releases#/embedded/release/70602999/1vision-biogas-will-change-its-name-to-st1-biokraft?publisherId=69820571&lang=en&widget=true',
        '/a-closer-look-under-the-hood-of-our-energy-sectors': '/about-us/sustainability/energy-transition/energy-transition-roadmap',
        '/a-road-we-have-not-yet-taken-is-the-way': '/about-us/stories',
        '/about-st1/company-information/areas-operations': '/about-us/company-info/areas-of-operation',
        '/about-st1/company-information/areas-operations/direct-sales': '/about-us/company-info/areas-of-operation/direct-sales',
        '/about-st1/company-information/areas-operations/future-energy-solutions': '/about-us/sustainability/energy-transition',
        '/about-st1/company-information/areas-operations/high-quality-fuels-nordic-drivers': '/about-us/company-info/areas-of-operation',
        '/about-st1/company-information/areas-operations/nordic-retail-station-network': '/about-us/company-info/areas-of-operation/retail-station-network',
        '/about-st1/company-information/areas-operations/payment-services': '/about-us/company-info/areas-of-operation',
        '/about-st1/company-information/areas-operations/renewable-energy': '/about-us/sustainability/energy-transition',
        '/about-st1/company-information/areas-operations/research-and-development': '/about-us/company-info/areas-of-operation',
        '/about-st1/company-information/areas-operations/research-development/future-energy-solutions': '/about-us/sustainability/energy-transition',
        '/about-st1/company-information/areas-operations/smart-fuels-nordic-drivers': '/about-us/company-info/areas-of-operation',
        '/about-st1/company-information/areas-operations/st1-refinery': '/about-us/company-info/areas-of-operation/st1-refinery',
        '/about-st1/company-information/areas-operations/st1-refinery?src=empad-sprout&blaid=5139123': '/about-us/company-info/areas-of-operation/st1-refinery',
        '/about-st1/company-information/areas-operations/st1-refinery?src=seo-subsurface-interpretation-and-visualization': '/about-us/company-info/areas-of-operation/st1-refinery',
        '/about-st1/company-information/areas-operations/supply-and-logistics': '/about-us/company-info/areas-of-operation/supply-and-logistics',
        '/about-st1/company-information/company-information': '/about-us/company-info',
        '/about-st1/company-information/company-information/associated-companies': '/about-us/company-info/associated-companies',
        '/about-st1/company-information/company-information/key-figures-investor-relations/disclaimer': '/about-us/company-info/key-figures-and-ir',
        '/about-st1/company-information/company-information/our-vision-values': '/about-us/company-info/vision-and-values',
        '/about-st1/company-information/game-changer-2022': '/about-us/sustainability/reporting',
        '/about-st1/company-information/game-changer-2022?page=2': '/about-us/sustainability/reporting',
        '/about-st1/company-information/game-changer-2022?page=3': '/about-us/sustainability/reporting',
        '/about-st1/company-information/game-changer-2023': '/about-us/sustainability/reporting',
        '/about-st1/company-information/game-changer-2023?page=1': '/about-us/sustainability/reporting',
        '/about-st1/company-information/game-changer-2023?page=2': '/about-us/sustainability/reporting',
        '/about-st1/company-information/game-changer-2023?page=3': '/about-us/sustainability/reporting',
        '/about-st1/contact-us': '/about-us/company-info/contact-us',
        '/about-st1/news-media': '/about-us/news-and-media',
        '/about-st1/people-career': '/about-us/people-and-careers',
        '/about-st1/people-career/open-opportunities': '/about-us/people-and-careers/open-positions',
        '/about-st1/people-career/summer-internship-program-2023': '/about-us/people-and-careers/open-positions',
        '/about/gamechanger-report': '/about-us/sustainability/reporting',
        '/about/gamechanger-report/ceo-review-challenging-operating-environment': '/about-us/sustainability/reporting',
        '/about/gamechanger-report/Energy-transition-Roadmap-2022': '/about-us/sustainability/energy-transition/energy-transition-roadmap',
        '/about/gamechanger-report/Energy-transition-Roadmap-2023': '/about-us/sustainability/energy-transition/energy-transition-roadmap',
        '/about/gamechanger-report/fit-for-the-future': '/about-us/sustainability/reporting',
        '/about/gamechanger-report/highlights-of-2021': '/about-us/sustainability/reporting',
        '/about/gamechanger-report/Our-sustainability-journey': '/about-us/sustainability/reporting',
        '/about/gamechanger-report/statement-of-the-chairman': '/about-us/sustainability/reporting',
        '/about/gamechanger-report/statement-of-the-chairman-2022': '/about-us/sustainability/reporting',
        '/about/gamechanger-report/statement-of-the-chairman-2023': '/about-us/sustainability/reporting',
        '/about/gamechanger-report/Sustainability-Framework': '/about-us/sustainability/reporting',
        '/about/gamechanger-report/sustainability-highlights-and-challenges': '/about-us/sustainability/reporting',
        '/about/gamechanger-report/Sustainable-energy-and-security-of-supply': '/about-us/sustainability/reporting',
        '/Aneo-HitecVision-and-St1-join-forces': '/about-us/news-and-media/company-and-press-releases',
        '/Biorefinery-Ostrand-by-SCA-and-St1-has-agreed-to-an-EU-innovation-grant': '/about-us/news-and-media/company-and-press-releases',
        '/capman-infra-and-st1-to-co-operate-on-the-constructions-of-ground-source-heat-plants': '/about-us/news-and-media/company-and-press-releases',
        '/catalogsearch/result/?q=%E6%88%90%E4%BA%BA%E8%89%B2%E6%83%85%E5%B0%8F%E8%AA%AA%EF%BC%8C%E7%A6%81%E5%BF%8C%E6%9B%B8%E5%B1%8B': '/',
        '/caverion-and-st1-lahienergia-combine-expertise-in-geothermal-projects-for-large-scale-buildings-in-finland': '/about-us/news-and-media/company-and-press-releases',
        '/cellunolix-ethanol-plant-to-be-built-in-finland': '/about-us/news-and-media/company-and-press-releases',
        '/change-in-the-publication-date-for-st1-nordics-financial-statements-release-2014': '/about-us/news-and-media/company-and-press-releases',
        '/climate-changes-everything': '/about-us/sustainability/energy-transition',
        '/collaboration-got-us-here-it-should-get-us-out': '/about-us/sustainability/energy-transition',
        '/collaboration-with-Enact': '/about-us/stories',
        '/competition-authority-approves-conditionally-st1-nordic-oys-acquisition-of-shell': '/about-us/news-and-media/company-and-press-releases',
        '/continuation-of-drilling-is-being-prepared-at-the-st1-geothermal-site-in-espoo': '/about-us/news-and-media/company-and-press-releases',
        '/customer-information-on-our-fuel-supply-chain': '/about-us/news-and-media/company-and-press-releases',
        '/dataprivacy@st1.fi': '/private/privacy-policy',
        '/dataprivacy@st1.no': '/private/privacy-policy',
        '/dataprivacy@st1.se': '/private/privacy-policy',
        '/decision-on-location-of-suomen-lantakaasu-oy-biogas-plant-in-finland': '/about-us/news-and-media/company-and-press-releases',
        '/Delivering-results-in-challenging-times': '/about-us/stories',
        '/Discovering-our-overall-impact-on-society': '/about-us/stories',
        '/drilling-on-st1s-geothermal-pilot-project-at-otaniemi-will-be-paused-for-approxi': '/about-us/news-and-media/company-and-press-releases',
        '/Driving-energy-transition': '/about-us/stories',
        '/electrification-needs-us-to-combine-our-powers': '/about-us/stories',
        '/energy-company-st1-participates-in-developing-wind-farms-in-norway': '/about-us/news-and-media/company-and-press-releases',
        '/energy-company-st1-participates-in-developing-wind-farms-in-norway?gsid=607f279c-6b5a-47c0-afb5-72717acc6995': '/about-us/news-and-media/company-and-press-releases',
        '/energy-company-st1-participates-in-developing-wind-farms-in-norway?gsid=9a566014-cea8-42f4-ae3d-17390a671967': '/about-us/news-and-media/company-and-press-releases',
        '/eu-news': '/about-us/news-and-media/company-and-press-releases',
        '/feasibility-study-on-synthetic-fuels-pilot-plant-in-joutseno-started': '/about-us/news-and-media/company-and-press-releases',
        '/fi/?trk=public_post_main-feed-card-text': 'https://st1.fi/tietoa-meista',
        '/fi/1vision-biogasin-tavoitteena-tulla-pohjoismaiden-johtavaksi-biokaasutoimijaksi': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet#/embedded/release/70471894/1vision-biogasin-tavoitteena-tulla-pohjoismaiden-johtavaksi-biokaasutoimijaksi?publisherId=69820571&lang=fi&widget=true',
        '/fi/1vision-biogasista-tulee-st1-biokraft': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet#/embedded/release/70602999/1vision-biogasista-tulee-st1-biokraft?publisherId=69820571&lang=fi&widget=true',
        '/fi/ajankohtaista-tietoa-polttonesteiden-hankintaketjustamme': 'https://st1.fi/tietoa-meista/st1-yrityksena/liiketoiminnot/hankinta-ja-logistiikka',
        '/fi/Aneo-HitecVision-and-St1-join-forces': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/asemahaku/': 'https://st1.fi/yksityisille/palvelut/asemahaku',
        '/fi/capman-infra-ja-st1-sopivat-yhteistyosta-maalampolaitosten-rakentamiseksi': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/caverion-ja-st1-lahienergia-yhdistavat-nyt-osaamisensa-suurkiinteistojen-maalampoprojekteissa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/corporate.vattenfall.se': 'https://st1.fi/tietoa-meista',
        '/fi/Davvin_tuulipuistohankkeen_sosiaalinen_vastuu_ja_vaikutusten_arviointi': 'https://st1.fi/tietoa-meista/vastuullisuus',
        '/fi/Davvin-tuulivoimapuistohanketta-tehdaan-paikallisyhteisojen-kanssa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/fi/st1-stories-hallituksen-puheenjohtajan-katsaus-kun-on-tahtoa-myos-keinot-keksitaan': 'https://st1.fi/tietoa-meista/stories',
        '/fi/fi/st1-stories-merkittavia-raskaan-liikenteen-paastovahennyksia-voitaisiin-tehda-jo-nyt': 'https://st1.fi/artikkeli/merkittavia-raskaan-liikenteen-paastovahennyksia-voitaisiin-tehda-jo-nyt',
        '/fi/fi/st1-stories-rajat-ylittava-yhteistyo-tarkeampaa-kuin-koskaan-aiemmin': 'https://st1.fi/tietoa-meista/stories',
        '/fi/fi/st1-stories-st1-rakentaa-kaksi-raskaan-liikenteen-biokaasun-tankkausasemaa-suomeen': 'https://st1.fi/tietoa-meista/stories',
        '/fi/fi/st1-stories-toimitusjohtajan-katsaus-keskiossa-kestava-energiantuotanto-ja-toimitusvarmuus': 'https://st1.fi/tietoa-meista/stories',
        '/fi/henrikki-talvitie-st1-oyn-uudeksi-toimitusjohtajaksi': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/inkoon-terminaalihanke': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/kati-ihamaki-valittiin-st1-nordicin-hallitukseen': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/konsernitiedote-01-12-2021': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/kryptovaluuttaa-kaupataan-huijaustarkoituksissa-anttosen-nimissa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/Kutsu_St1_Nordic_Oy:n_ylimaaraiseen_yhtiokokoukseen': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/kutsu-st1-nordic-oyn-varsinaiseen-yhtiokokoukseen': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/kutsu-st1-nordic-oyn-varsinaiseen-yhtiokokoukseen-2024': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/kutsu-varsinaiseen-yhtiokokoukseen': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/kutsu-ylimaaraiseen-yhtiokokoukseen': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/Ladulle-tielta-St1n-hiihtotempaus-tukee-autoilijoiden-tiehyvinvointia': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/lea-rankinen-nimitetty-sustainability-communications-and-public-affairs-business-unit-lead-tehtavaan-st1-nordic-oyssa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/lut-wartsila-ja-st1-power-to-x-ratkaisut-tulee-nostaa-suomen-energia-ja-ilmastoratkaisujen-ytimeen': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/mika-eerola-st1-refinery-abn-toimitusjohtajaksi': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/nivoksen-biokaasun-tankkausasema-mantsalassa-siirtyy-st1n-omistukseen': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/node/136': 'https://st1.fi/tietoa-meista',
        '/fi/osakerekisteri@st1.fi': 'https://st1.fi/tietoa-meista',
        '/fi/palvelumestari-konsepti-paattyy-suomessa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/peab-asfaltin-pilotissa-osa-bitumista-on-korvattu-St1n-biopohjaisella-ligniinilla': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/SAKlaisten-liittojen-lakko': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/SAKlaisten-liittojen-lakkouhka': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/sampsa-halinen-valittiin-st1-nordicin-hallitukseen': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/sca-ja-st1-ovat-perustaneet-yhteisyrityksen-tuottamaan-ja-kehittamaan-nestemaisia-biopolttoaineita': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/SCAn-ja-St1n-Biorefinery-Ostrandille-sopimus-EUn-innovaatioavustuksesta': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-aloitti-biokaasuliiketoiminnan-ruotsissa-yhtion-biokaasuhankkeet-etenevat-myos-norjassa-ja-suomessa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-avasi-biokaasun-tankkauspisteet-raskaalle-liikenteelle-iittalassa-ja-mantsalassa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-brandaa-uudelleen-shell-asemaverkostonsa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-completed-the-purchase-of-the-shares-of-statoil-fuel-and-retail-marine-as-in-norway': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-geolampoprojekti-on-edennyt-suunnitelmien-mukaan': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/St1-investoi-aurinkoenergiaan': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-investoi-biokaasuliiketoimintaan-ruotsissa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-ja-caverion-laajentavat-yhteistyotaan': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-ja-compensate-yhteistyohon-liikenteen-hiilijalanjaljen-kompensoinnissa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-ja-horisont-energi-yhteistyohon-vihrean-ammoniakin-tuotannossa-finnmarkissa-norjassa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-ja-sca-yhteisyritys-aloittaa-uusiutuvien-polttoaineiden-tuotannon-goteborgissa-ruotsissa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-ja-skal-yhteistyohon': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-kuluttajalammitysoljytilaukset-keskitetaan-tytaryhtiolle-lampopuisto-oylle': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-laajentaa-biokaasuliiketoimintaan-hankkimalla-eon-bioforin-ruotsissa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-laajentaa-biokaasuliiketoimintaan-norjassa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/St1-laajentaa-sahkoautojen-suurteholatausverkostoaan': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-lyhyesti/ihmiset-ja-ura': 'https://st1.fi/tietoa-meista/ihmiset-ja-ura',
        '/fi/st1-lyhyesti/ihmiset-ja-ura/arne-karlsson-st1-ruotsi': 'https://st1.fi/tietoa-meista/ihmiset-ja-ura',
        '/fi/st1-lyhyesti/ihmiset-ja-ura/mikael-oberg-st1-jalostamo-ruotsi': 'https://st1.fi/tietoa-meista/ihmiset-ja-ura',
        '/fi/st1-lyhyesti/ihmiset-ja-ura/randi-kvaloy-st1-norja': 'https://st1.fi/tietoa-meista/ihmiset-ja-ura',
        '/fi/st1-lyhyesti/ihmiset-ja-ura/tia-rytkonen-st1-suomi': 'https://st1.fi/tietoa-meista/ihmiset-ja-ura',
        '/fi/st1-lyhyesti/nordic-energy-outlook': 'https://st1.fi/tietoa-meista/vastuullisuus/energiatransitio/tiekartta',
        '/fi/st1-lyhyesti/ota-yhteytta': 'https://st1.fi/tietoa-meista/st1-yrityksena/yhteystiedot',
        '/fi/st1-lyhyesti/tietoa-yrityksesta-ja-avainluvut/liiketoiminta-alueet/hankinta-ja-logistiikka': 'https://st1.fi/tietoa-meista/st1-yrityksena/liiketoiminnot/hankinta-ja-logistiikka',
        '/fi/st1-lyhyesti/tietoa-yrityksesta-ja-avainluvut/liiketoiminta-alueet/maksupalvelut': 'https://st1.fi/tietoa-meista/st1-yrityksena/liiketoiminnot',
        '/fi/st1-lyhyesti/tietoa-yrityksesta/liiketoiminta-alueet': 'https://st1.fi/tietoa-meista/st1-yrityksena/liiketoiminnot',
        '/fi/st1-lyhyesti/tietoa-yrityksesta/liiketoiminta-alueet/fiksuja-polttoaineita-pohjoismaisille': 'https://st1.fi/tietoa-meista/st1-yrityksena/liiketoiminnot',
        '/fi/st1-lyhyesti/tietoa-yrityksesta/liiketoiminta-alueet/hankinta-ja-logistiikka': 'https://st1.fi/tietoa-meista/st1-yrityksena/liiketoiminnot/hankinta-ja-logistiikka',
        '/fi/st1-lyhyesti/tietoa-yrityksesta/liiketoiminta-alueet/pohjoismainen-jakeluasemaverkosto': 'https://st1.fi/tietoa-meista/st1-yrityksena/liiketoiminnot/asemaverkosto',
        '/fi/st1-lyhyesti/tietoa-yrityksesta/liiketoiminta-alueet/st1-jalostamo': 'https://st1.fi/tietoa-meista/st1-yrityksena/liiketoiminnot/st1-jalostamo',
        '/fi/st1-lyhyesti/tietoa-yrityksesta/liiketoiminta-alueet/suoramyynti': 'https://st1.fi/tietoa-meista/st1-yrityksena/liiketoiminnot/yritysmyynti',
        '/fi/st1-lyhyesti/tietoa-yrityksesta/liiketoiminta-alueet/uusiutuva-energia': 'https://st1.fi/tietoa-meista/vastuullisuus/energiatransitio',
        '/fi/st1-lyhyesti/tietoa-yrityksesta/tietoa-yrityksesta-ja-avainluvut': 'https://st1.fi/tietoa-meista/st1-yrityksena',
        '/fi/st1-lyhyesti/tietoa-yrityksesta/tietoa-yrityksesta-ja-avainluvut/visiomme-ja-arvomme': 'https://st1.fi/tietoa-meista/st1-yrityksena/visiomme-ja-arvomme',
        '/fi/st1-lyhyesti/tietoa-yrityksesta/tietoa-yrityksesta-ja-avainluvut/yhteisyritykset': 'https://st1.fi/tietoa-meista/st1-yrityksena/yhteisyritykset',
        '/fi/st1-lyhyesti/uutiset-ja-media': 'https://st1.fi/tietoa-meista/uutiset-ja-media',
        '/fi/st1-mastercard-korttimme-kaytto-venajalla-keskeytyy': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-mukaan-mahdollistamaan-tahdon-ymparistoaiheista-nayttelya': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-myi-tuulivoimakapasiteettinsa-suomessa-exilion-tuulelle': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-myy-nykyisen-tuulivoimakapasiteettinsa-suomessa-ja-laajentaa-tuulivoimatuotantoaan-pohjoismaihin': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-nordic-oy:n-osinnonjako': 'https://st1.fi/tietoa-meista/st1-yrityksena/avainluvut-ja-sijoittajasuhteet',
        '/fi/st1-nordic-oyn-tilinpaatostiedo-2018': 'https://st1.fi/tietoa-meista/st1-yrityksena/avainluvut-ja-sijoittajasuhteet',
        '/fi/st1-nordic-oyn-tilinpaatostiedote-2021': 'https://st1.fi/tietoa-meista/st1-yrityksena/avainluvut-ja-sijoittajasuhteet',
        '/fi/st1-nordic-oyn-tilinpaatostiedote-2022': 'https://st1.fi/tietoa-meista/st1-yrityksena/avainluvut-ja-sijoittajasuhteet',
        '/fi/St1-Nordic-Oyn-tilinpaatostiedote-2023': 'https://st1.fi/tietoa-meista/st1-yrityksena/avainluvut-ja-sijoittajasuhteet',
        '/fi/st1-nordic-oyn-valitilinpaatostiedote-tammi--kesakuu-2022': 'https://st1.fi/tietoa-meista/st1-yrityksena/avainluvut-ja-sijoittajasuhteet',
        '/fi/st1-nordic-oyn-valitilinpaatostiedote-tammi-kesakuu-2019': 'https://st1.fi/tietoa-meista/st1-yrityksena/avainluvut-ja-sijoittajasuhteet',
        '/fi/st1-nordic-oyn-valitilinpaatostiedote-tammi-kesakuu-2020': 'https://st1.fi/tietoa-meista/st1-yrityksena/avainluvut-ja-sijoittajasuhteet',
        '/fi/st1-nordic-oyn-valitilinpaatostiedote-tammi-kesakuu-2021': 'https://st1.fi/tietoa-meista/st1-yrityksena/avainluvut-ja-sijoittajasuhteet',
        '/fi/st1-nordic-oyn-valitilinpaatostiedote-tammi-kesakuu-2023': 'https://st1.fi/tietoa-meista/st1-yrityksena/avainluvut-ja-sijoittajasuhteet',
        '/fi/st1-nordic-oyn-valitilinpaatostiedote-tammi-kesakuu-2024': 'https://st1.fi/tietoa-meista/st1-yrityksena/avainluvut-ja-sijoittajasuhteet',
        '/fi/st1-nordic-oyn-varsinainen-yhtiokokous-2020': 'https://st1.fi/tietoa-meista/st1-yrityksena/avainluvut-ja-sijoittajasuhteet',
        '/fi/st1-nordicin-ja-st1-groupin-avainluvut': 'https://st1.fi/tietoa-meista/st1-yrityksena/avainluvut-ja-sijoittajasuhteet',
        '/fi/st1-on-avannut-sahkoautojen-nopean-suurteholatausverkoston': 'https://st1.fi/tietoa-meista/vastuullisuus/energiatransitio/sahkolatausverkosto',
        '/fi/st1-rakentaa-biojalostamon-uusiutuvan-dieselin-ja-lentopolttoaineen-tuottamiseksi': 'https://st1.fi/tietoa-meista/vastuullisuus/energiatransitio/nestemaiset-biopolttoaineet',
        '/fi/st1-rakentaa-biokaasun-jalostus--ja-nesteytyslaitoksen-ruotsiin': 'https://st1.fi/tietoa-meista/vastuullisuus/energiatransitio/biokaasu',
        '/fi/st1-rakentamassa-raskaalle-liikenteelle-biokaasun-tankkauspisteita-suomessa': 'https://st1.fi/tietoa-meista/vastuullisuus/energiatransitio/biokaasu',
        '/fi/st1-stories': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories-globaalit-signaalit-antavat-syyta-optimismiin': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories-goteborgin-jalostamo-kehittaa-turvallisuuskulttuuria-kayttaytymispohjaisen-ohjelman-avulla': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories-hiiliviljely-osana-maatilojen-tuotantoa': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories-investoinnit-epavakaassa-toimintaymparistossa': 'Uusiutuvan energian investoinnit epävakaassa toimintaympäristössä | St1 Finland',
        '/fi/st1-stories-joka-paiva-lahemmas-taydellista': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories-kestavan-kehityksen-kohokohdat-ja-haasteet': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories-kohti-asfaltin-kestavampaa-hiilikiertoa': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories-osaamisen-kehittamisohjelma-uusiutuvan-dieselin-arvoketjun-parissa-valmentaa-tulevia-johtajia-energiasiirtymaan': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories-rakennamme-biojalostamon-uusiutuvan-dieselin-tuottamiseksi': 'https://st1.fi/tietoa-meista/vastuullisuus/energiatransitio/nestemaiset-biopolttoaineet',
        '/fi/st1-stories-shell-sipoo-bastuksrissa-voi-tankata-myos-uusiutuvaa-hvo-dieselia': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories-st1-suunnittelee-synteettisen-metanolin-pilottilaitosta-lappeenrantaan': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories-st1-vahvistaa-arvoketjuaan-ostamalla-brocklesby-ltdn': 'https://st1.fi/artikkeli/st1-vahvistaa-arvoketjuaan-ostamalla-brocklesby-ltd-n-iso-britanniasta',
        '/fi/st1-stories-st1n-sisainen-tekoalyavustaja-pluto': 'St1:n sisäinen tekoälyavustaja Pluto parantaa tietoturvaa ja ymmärrystä generatiivisesta tekoälystä | St1 Finland',
        '/fi/st1-stories-suomen-lantakaasu-oyn-tavoitteena-on-lisata-kotimaista-biokaasuntuotantoa': 'https://st1.fi/tietoa-meista/vastuullisuus/energiatransitio/biokaasu',
        '/fi/st1-stories-toimitusjohtajan-katsaus-haastava-toimintaymparisto': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories-toimitusjohtajan-katsaus-valmiina-tulevaisuuteen': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories-tulevaisuuden-jakeluasema': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories-tutkimuksen-kohteena-power-to-x': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories-valion-ja-st1n-yhteisyritys-valmiina-lisaamaan-kotimaista-biokaasuntuotantoa': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories-vantaan-energia-ja-st1-yhteistyohon-sahkopolttoaineen-jakelussa': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories-vastuullisuusjohtajan-katsaus-vuoden-2021-kohokohdat-ja-haasteet': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories-vattenfall-ja-st1-yhteistyohon-lentoliikenteen-fossiilittoman-sahkopolttoaineen-mittavassa-tuotannossa': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/asemamme-ovat-avoinna': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/energia-alan_edellakavija': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/etanoliautoilu': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/etanoliautoilu-yleistyy': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/helmisimpukka-neljanneksi-suurin-ravintolaketju': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/hiilidioksidin-poistopotentiaalin-tutkimista': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/metsittaminen-tehokas-keino-hillita-ilmastonmuutosta': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/miten-asemaverkostoamme-kunnostetaan': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/miten-huoltoasema-rakennetaan': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/miten-polttonesteita-toimitetaan-metsatyokohteisiin': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/saasta-rahaa-ja-ymparistoa-ryhdy-etanoliautoilijaksi': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/siirtyminen-etanoliautoiluun-tuo-selvaa-saastoa': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/st1-mpo-plus': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/st1-outlook': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/st1-palvelee': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/st1-suomalaisen-maastohiihdon-tukija': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/synteettisia-polttoaineita-hiilidioksidia-talteen-ottamalla': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/tarkkelystehtaiden-jatteesta-syntyy-etanolia': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/tulevaisuuden-huippuhiihtajat': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/turvallista-talviautoilua': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/valio-ja-st1-tuottamaan-biokaasua-lannasta': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-stories/ymparistoystavallisen-RE85-polttonesteen-kysynta-kasvaa': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-suunnittelee-inkooseen-terminaalia-uusiutuvien-polttonesteiden-jakelun-kasvattamiseksi': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-suunnittelee-synteettisen-metanolin-pilottilaitosta-lappeenrantaan': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-tarjoaa-asiakkailleen-tiehyvinvointia-ainutlaatuinen-hiihtomahdollisuus-valikoiduilla-asemilla': 'https://st1.fi/tietoa-meista/stories',
        '/fi/st1-tuo-myyntiin-uusiutuvan-hvo-diesel-dieselpolttoaineen': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-vahvistaa-jateraaka-aineliiketoimintaansa-ostamalla-brocklesby-ltdn-iso-britanniasta': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-yhteistyohon-sfcaravanin-kanssa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1-yhtio-ja-lehdistotiedotteet': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/St1n-asemaverkoston-kolmas-raskaan-liikenteen-biokaasun-tankkauspiste-avautui-Salon-Halikkoon': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1n-etanolintuotanto-paattyy-vantaalla-lahdessa-ja-kajaanissa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1n-geoterminen-pilottihanke-espoon-otaniemessa-etenee-lampokaivojen-viimeistelyvaiheeseen': 'https://st1.fi/tietoa-meista/stories',
        '/fi/St1n-geotermisilla-lampokaivoilla-Otaniemessa-aloitettiin-tutkimustyo': 'https://st1.fi/artikkeli/st1-n-geotermisilla-lampokaivoilla-otaniemessa-aloitettiin-tutkimustyo',
        '/fi/st1n-inkooseen-suunnitellun-terminaalihankkeen-ymparistovaikutusten-arivointiselostus-on-valmistunut': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/St1n-Lappeenrannan-P2X-hanke-ei-etene-alkuperaisessa-aikataulussa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1n-marokon-metsityspilotin-tulokset-vahvistavat-hiilinieluja-voidaan-luoda-jopa-kuivissa-olosuhteissa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1n-mittausverkostossa-havaittiin-1': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1n-mittausverkostossa-havaittiin-1,67-magnitudin-mikrojaristys': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/St1n-Otaniemen_geotermisen_lampolaitoksen_pilottiprojekti_etenee_virtaustestivaiheeseen': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1n-otaniemen-geotermisen-lampolaitoksen-pilottiprojektissa-selvitetaan-lammontuotantotapoja-': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/st1n-otaniemen-geotermisen-pilottiprojektin-syvat-lampokaivot-tutkimuskayttoon': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/stationlocator/map/': 'https://st1.fi/asemahaku',
        '/fi/stationlocator/map/?___store=st1_website_eu_fi': 'https://st1.fi/asemahaku',
        '/fi/stiina.tuunanen@st1.com': 'https://st1.fi/tietoa-meista',
        '/fi/Suomen-Lantakaasu-investoi-yli-80-miljoonaa-Kiuruveden-biokaasulaitoshankkeeseen': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/Suomen-Lantakaasu-ja-Atria-Suomi-investoivat-biokaasulaitokseen-Nurmossa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet#/embedded/release/70671503/suomen-lantakaasu-ja-atria-suomi-investoivat-biokaasulaitokseen-nurmossa?publisherId=69820571&lang=fi&widget=true',
        '/fi/suomen-lantakaasu-oy-aloittaa-biokaasulaitoksen-maanrakennustyot-kiuruvedella': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/Suomen-Lantakaasu-Oy-edistaa-biokaasulaitosinvestointeja-Pohjois-Keski-ja-Etela-Pohjanmaalle': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/suomen-lantakaasu-oy-edistaa-kahden-uuden-biokaasulaitoksen-kehittamista-pohjanmaalla': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/Suomen-Lantakaasu-Oy-ostaa-Nurmon-Bioenergian': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/suomen-lantakaasu-oyn-biokaasulaitoksen-sijaintipaikasta-paatos-kiuruvedella-biokaasulaitosta-suunnitellaan-pyhasalmentien-etelapuolelle': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/suomenhiihtoliiton-ja-st1n-valinen-historiallinen-kumppanuus-vie-kohti-seuraavaa-olympiadia': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/synteettisten-polttoaineiden-pilotlaitoksen-selvitystyo-joutsenoon-kaynnistyi': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/tilinpaatostiedote-2019': 'https://st1.fi/tietoa-meista/st1-yrityksena/avainluvut-ja-sijoittajasuhteet',
        '/fi/tilinpaatostiedote-2020': 'https://st1.fi/tietoa-meista/st1-yrityksena/avainluvut-ja-sijoittajasuhteet',
        '/fi/tom-rinne-nimitetty-group-hr-director-tehtavaan-st1-nordic-oyssa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/turku-energia-hakee-toimenpidelupaa-geotermista-laitosta-varten-porattaville-lampokaivoille': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/tutkimus-suomella-hyvat-mahdollisuudet-power-to-x-tuotantoon-perameren-alueella': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/tuulivoima/tcm': 'https://st1.fi/yrityksille/energiatuotteet/b2b-tuuli',
        '/fi/tuuliwatti-rakensi-markkinaehtoisen-tuulipuiston-iin-viinamakeen': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/valio-ja-st1-selvittivat-yla-savon-maanviljelijoilla-laajaa-kiinnostusta-biokaasuntuotantoon-osallistumiseen': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/valio-ja-st1-tuottamaan-biokaasua-lannasta-uusi-pelinavaus-liikenteen-paastojen-pienentamiseksi': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/valion-ja-st1n-yhteisyritys-suomen-lantakaasu-oy-suunnittelee-biokaasulaitosinvestointia-lapinlahdelle-sonkajarvelle-ja-nurmekseen': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/valion-ja-st1n-yhteisyritys-suomen-lantakaasu-oy-valmiina-lisaamaan-kotimaista-biokaasuntuotantoa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/valion-ja-st1n-yhteisyritys-suomen-lantakaasu-oyn-biokaasulaitoskokonaisuuden-suunnittelu-etenee': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/vantaan-energia-ja-st1-yhteistyohon-sahkopolttoaineen-jakelussa': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/vastuunrajaus': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/vattenfall-ja-st1-yhteistyohon-lentoliikenteen-fossiilittoman-sahkopolttoaineen-mittavassa-tuotannossa-ruotsin-lansirannikolla': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/vtt-ja-st1-pilotoivat-bio-ja-kiertotaloutta-ilmastoystavallisia-hiilivetyja-teollisuudenhiilidioksidista': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/www.karavaanarit.fi/jasenyys/muutokset-jasenyyteen': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/fi/www.st1.com.': 'https://st1.fi/tietoa-meista',
        '/fi/yrityksille/infra-kumppanuus': 'https://st1.fi/tietoa-meista/uutiset-ja-media/lehdisto-ja-yhtiotiedotteet',
        '/file_archive': '/',
        '/Finding-new-ways-to-produce-advanced-bio-ethanol': '/about-us/stories',
        '/frends-prod-test': '/',
        '/geothermal_heat_feedback': '/',
        '/geothermal-heat': '/',
        '/group-announcement': '/about-us/news-and-media/company-and-press-releases',
        '/group-announcement-01-12-2021': '/about-us/news-and-media/company-and-press-releases',
        '/henrikki-talvitie-appointed-as-the-new-ceo-of-st1-oy': '/about-us/news-and-media/company-and-press-releases',
        '/henrikki-talvitie-is-the-new-ceo-of-st1-nordic-oy': '/about-us/news-and-media/company-and-press-releases',
        '/hilde-wahl-ny-vd-pa-st1-fortsatter-kampen-for-en-fossilfri-fordonsflotta': '/about-us/news-and-media/company-and-press-releases',
        '/homo-flammabus-caused-an-unsustainable-carbon-cycle': '/about-us/sustainability/energy-transition',
        '/How-to-commercialize-bioproducts-to-replace-fossil-products': '/about-us/stories',
        '/introduction-the-crux-of-the-global-energy-challenge': '/about-us/sustainability/energy-transition',
        '/kati-ihamaki-was-elected-to-st1-nordics-board-of-directors': '/about-us/news-and-media/company-and-press-releases',
        '/kristine-vergli-grant-carlsen-appointed-as-the-new-ceo-of-st1-norge-as': '/about-us/news-and-media/company-and-press-releases',
        '/kutsu_st1_nordic_oyn_varsinaiseen_yhtiokokoukseen': '/about-us/news-and-media/company-and-press-releases',
        '/lea-rankinen-appointed-lead-of-sustainability-communications-and-public-affairs-business-unit-of-st1-nordic-oy': '/about-us/news-and-media/company-and-press-releases',
        '/Mattias.Wesslau@nordionenergi.se': '/',
        '/miika-eerola-appointed-the-ceo-of-St1-Refinery-Ab': '/about-us/news-and-media/company-and-press-releases',
        '/mikael-oberg': '/',
        '/nb': 'https://st1.no/om-oss',
        '/nb/node/64': 'https://st1.no/om-oss',
        '/nb/node/81': 'https://st1.no/om-oss',
        '/nb/node/92': 'https://st1.no/om-oss',
        '/nb/om-st1/st1-historie-no': 'https://st1.no/om-oss',
        '/nb/om-st1/var-visjon': 'https://st1.no/om-oss',
        '/nb/stationlocatormap': 'https://st1.no/privat/tjenester/finn-stasjon',
        '/Nordion-Energi-and-St1-in-cooperation-on-liquefied-biogas': '/about-us/news-and-media/company-and-press-releases',
        '/norwegian-smart-fuel-as-transferred-under-the-ownership-of-st1-nordic': '/about-us/news-and-media/company-and-press-releases',
        '/notice-of-extraordinary-general-meeting': '/about-us/news-and-media/company-and-press-releases',
        '/notice-of-st1-nordic-annual-general-meeting': '/about-us/news-and-media/company-and-press-releases',
        '/notice-of-st1-nordic-oys-annual-general-meeting': '/about-us/news-and-media/company-and-press-releases',
        '/notice-to-st1-nordic-oys-annual-general-meeting': '/about-us/news-and-media/company-and-press-releases',
        '/notice-to-the-general-annual-meeting': '/about-us/news-and-media/company-and-press-releases',
        '/one-market-with-one-rulebook': '/about-us/sustainability/energy-transition',
        '/osakerekisteri@st1.fi': '/',
        '/per-arne-karlsson': '/',
        '/plans-for-biogas-production-plant-by-valio-and-st1s-joint-venture-suomen-lantakaasu-oy-progressing': '/about-us/news-and-media/company-and-press-releases',
        '/power-to-x-the-possibilities-of-electricity': '/about-us/sustainability/energy-transition',
        '/privacy-policy': '/private/privacy-policy',
        '/q-power-and-st1-piloting-synthetic-fuel-production-from-biorefinery-carbon-dioxide': '/about-us/news-and-media/company-and-press-releases',
        '/randi-kvaloy': '/about-us/people-and-careers',
        '/result-of-the-written-procedure-the-proposed-resolution-adopted': '/about-us/news-and-media/company-and-press-releases',
        '/saila.horttanainen@nordionenergi.se': '/',
        '/sampsa-halinen-appointed-director-energy-trade-and-logistics-business-unit-in-st1-nordic-oy': '/about-us/news-and-media/company-and-press-releases',
        '/sampsa-halinen-was-elected-to-st1-nordics-board-of-directors': '/about-us/news-and-media/company-and-press-releases',
        '/sca-and-st1-enter-joint-venture-to-produce-and-develop-liquid-biofuels': '/about-us/news-and-media/company-and-press-releases',
        '/Scientists-developing-an-international-measuring-concept-for-carbon-sequestration-in-afforestation-to-slow-down-climate-change': '/about-us/news-and-media/company-and-press-releases',
        '/Securing-fuel-supply': '/about-us/stories',
        '/shift': '/about-us/sustainability/climate-and-nature/carbon-sequestration',
        '/shift/customer-impact': '/about-us/sustainability/climate-and-nature/carbon-sequestration',
        '/shift/faq': '/about-us/sustainability/climate-and-nature/carbon-sequestration',
        '/shift/kenya-forest-project': '/about-us/sustainability/climate-and-nature/carbon-sequestration',
        '/shift/news-&-updates': '/about-us/sustainability/climate-and-nature/carbon-sequestration',
        '/shift/project-selection-criteria': '/about-us/sustainability/climate-and-nature/carbon-sequestration',
        '/shift/projects': '/about-us/sustainability/climate-and-nature/carbon-sequestration',
        '/Social_Sustainability_and_Impact_Assessment_of_the_Davvi_Wind_Farm_Project': '/about-us/sustainability/energy-transition/wind-power',
        '/St1_Nordic_Oy_Integrated_Annual_Report_2019_published': '/about-us/sustainability/reporting',
        '/St1_Nordic_Oy_joins_the_UN_Global_COmpact': '/about-us/sustainability',
        '/st1-and-compensate-collaborate-on-compensating-the-carbon-footprint-of-transport': '/about-us/news-and-media/company-and-press-releases',
        '/st1-and-fortum-to-start-a-pilot-project-for-geothermal-heat-production-in-espoo': '/about-us/news-and-media/company-and-press-releases',
        '/st1-and-horisont-energi-to-collaborate-on-green-ammonia-production-in-finnmark': '/about-us/news-and-media/company-and-press-releases',
        '/st1-and-sca-form-partnership-to-produce-renewable-fuels': '/about-us/news-and-media/company-and-press-releases',
        '/st1-and-sca-joint-venture-commences-the-production-of-renewable-fuels-in-gothenburg-sweden': '/about-us/news-and-media/company-and-press-releases',
        '/st1-begins-the-drilling-of-geothermal-deep-rock-wells-in-otaniemi-espoo': '/about-us/news-and-media/company-and-press-releases',
        '/st1-biogas-ab-merges-to-st1-sverige-ab': '/about-us/news-and-media/company-and-press-releases',
        '/st1-built-a-waste-based-etanolix-ethanol-production-plant-in-gothenburg': '/about-us/news-and-media/company-and-press-releases',
        '/st1-buys-marine-business-in-norway': '/about-us/news-and-media/company-and-press-releases',
        '/st1-completed-the-purchase-of-the-shares-of-statoil-fuel-and-retail-marine-as-in-norway': '/about-us/news-and-media/company-and-press-releases',
        '/st1-constructs-a-biorefinery-to-produce-renewable-diesel-and-jet-fuel': '/about-us/news-and-media/company-and-press-releases',
        '/st1-enters-biogas-business-by-acquiring-eon-biofor-in-sweden': '/about-us/news-and-media/company-and-press-releases',
        '/st1-enters-the-biogas-market-in-Norway': '/about-us/news-and-media/company-and-press-releases',
        '/st1-geothermal-pilot-project-otaniemi-espoo-reaches-stimulation-stage': '/about-us/news-and-media/company-and-press-releases',
        '/St1-geothermal-wells-in-Otaniemi-now-in-research-use': '/article/st1-geothermal-wells-in-otaniemi-now-in-research-use',
        '/st1-group-oy-merged-into-st1-nordic-oy': '/about-us/news-and-media/company-and-press-releases',
        '/st1-group-oy-merges-into-st1-nordic-oy': '/about-us/news-and-media/company-and-press-releases',
        '/st1-groups-key-figures': '/about-us/company-info/key-figures-and-ir',
        '/st1-has-made-an-investment-decision-to-construct-a-geothermal-heat-pilot-plant': '/about-us/news-and-media/company-and-press-releases',
        '/st1-invests-in-scandinavian-biogas-business': '/about-us/news-and-media/company-and-press-releases',
        '/St1-invests-in-solar-energy': '/about-us/sustainability/energy-transition/solar-energy',
        '/st1-is-planning-a-synthetic-methanol-pilot-plant-in-lappeenranta-finland': '/about-us/news-and-media/company-and-press-releases',
        '/St1-launches-RED95-renewable-fuel-for-the-trucking-industry-in-Norway': '/about-us/news-and-media/company-and-press-releases',
        '/st1-life': '/about-us/sustainability/climate-and-nature/carbon-sequestration/eu-life-carbon-farming-scheme',
        '/st1-life/news-and-updates': '/about-us/sustainability/climate-and-nature/carbon-sequestration/eu-life-carbon-farming-scheme',
        '/st1-life/partners-and-contact': '/about-us/sustainability/climate-and-nature/carbon-sequestration/eu-life-carbon-farming-scheme',
        '/st1-life/project': '/about-us/sustainability/climate-and-nature/carbon-sequestration/eu-life-carbon-farming-scheme',
        '/st1-nordic-corporation-financial-statements-release-2015': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-energy-outlook-published': '/about-us/sustainability/energy-transition',
        '/st1-nordic-eur-100-million-bond-to-be-listed-first-north-bond-market-finland': '/about-us/news-and-media/company-and-press-releases',
        '/st1-nordic-oy-company-release-interim-report-1-january-30-june-2015': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oy-dividend-distribution': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oy-finalised-the-sale-of-the-shares-of-st1-norge-automat-as': '/about-us/news-and-media/company-and-press-releases',
        '/st1-nordic-oy-financial-statements-release-2014': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oy-financial-statements-release-2016': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oy-financial-statements-release-2017': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oy-financial-statements-release-2018': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oy-financial-statements-release-2019': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oy-financial-statements-release-2020': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oy-financial-statements-release-2022': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oy-financial-statements-release-2023': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oy-financial-statements-release-january-june-2021': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oy-initiates-a-written-procedure-in-accordance-with-the-notes': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oy-interim-report-january-june-2016': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oy-interim-report-january-june-2017': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oy-interim-report-january-june-2018': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oy-interim-report-january-june-2019': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oy-interim-report-january-june-2020': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oy-sells-the-shares-of-st1-norge-as': '/about-us/news-and-media/company-and-press-releases',
        '/st1-nordic-oy-to-purchase-the-shares-of-shells-norwegian-downstream-company': '/about-us/news-and-media/company-and-press-releases',
        '/st1-nordic-oys-annual-general-meeting-2020': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oys-financial-statements-release-2021': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oys-interim-report-january-june-2022': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oys-interim-report-january-june-2023': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oys-interim-report-january-june-2024': '/about-us/company-info/key-figures-and-ir',
        '/st1-nordic-oys-sale-of-the-shares-of-st1-norge-automat-as-approved': '/about-us/news-and-media/company-and-press-releases',
        '/st1-nordic-purchases-aviation-fuelling-services-from-st1-group': '/about-us/news-and-media/company-and-press-releases',
        '/st1-norge-as-and-c2biotrade-has-signed-a-letter-of-intent-to-explore-a-combined-carbon-farming-and-sustainable-biofuels-concept-in-colombia': '/about-us/news-and-media/company-and-press-releases',
        '/st1-one-of-the-winners-of-energy-globe-world-award-2019': '/about-us/news-and-media/company-and-press-releases',
        '/st1-opened-biogas-refuelling-stations-for-heavy-transport-in-iittala-and-mantsala': '/about-us/news-and-media/company-and-press-releases',
        '/St1-opens-new-biogas-plant-in-Boras': '/about-us/news-and-media/company-and-press-releases',
        '/St1-organization-in-transformation': '/about-us/stories',
        '/st1-outlook': '/about-us/sustainability/energy-transition',
        '/st1-outlook-heating-and-cooling': '/about-us/sustainability/energy-transition',
        '/st1-outlook-industry': '/about-us/sustainability/energy-transition',
        '/st1-outlook-launch-webinar': '/about-us/sustainability/energy-transition',
        '/St1-Outlook-published': '/about-us/sustainability/energy-transition',
        '/st1-outlook-transport': '/about-us/sustainability/energy-transition',
        '/st1-pilots-sustainable-carbon-sequestrating-through-afforestation-morocco': '/about-us/sustainability/climate-and-nature/carbon-sequestration',
        '/st1-plans-to-extend-its-advanced-biofuels-production-into-renewable-diesel': '/about-us/news-and-media/company-and-press-releases',
        '/st1-refinery-deploys-aspentech-solutions': '/about-us/news-and-media/company-and-press-releases',
        '/st1-signed-a-memorandum-of-understanding-for-a-bioethanol-pilot-project-in-thail': '/about-us/news-and-media/company-and-press-releases',
        '/st1-signs-a-letter-of-intent-with-viken-skog-and-treklyngen-for-a-cellunolix-eth': '/about-us/news-and-media/company-and-press-releases',
        '/st1-sold-its-wind-power-capacity-in-finland-to-exilion-tuuli': '/about-us/news-and-media/company-and-press-releases',
        '/st1-started-biogas-business-in-Sweden-the-companys-biogas-projects-are-also-progressing-in-norway-and-finland': '/about-us/news-and-media/company-and-press-releases',
        '/st1-statement-on-crude-oil-sources-and-Shell-Co-operation': '/about-us/news-and-media/company-and-press-releases',
        '/st1-stories-an-energy-station-for-the-future': '/article/an-energy-station-for-the-future-with-a-focus-on-customer-experience',
        '/st1-stories-empowering-safety-culture-the-gothenburg-refinerys-behavioral-based-safety-program': '/about-us/stories',
        '/st1-stories-investments-in-a-volatile-operating-environment': '/article/investments-in-a-volatile-operating-environment',
        '/st1-stories-st1-norways-flagship-retail-site-revolutionizes-the-future-of-fueling': '/article/an-energy-station-for-the-future-with-a-focus-on-customer-experience',
        '/st1-stories-st1s-in-house-ai-assistant-pluto': '/article/rapid-advancements-in-artificial-intelligence-st1s-in-house-ai-assistant-pluto',
        '/st1-stories-suomen-lantakaasu-oy-accelerates-biogas-production-in-finland': '/article/st1-and-valio-join-forces-to-drive-renewable-biogas-production-from-dairy',
        '/st1-stories/driving-motivation-through-learning-a-leadership-journey': '/about-us/stories',
        '/st1-strengthens-waste-foodstock-business-by-acquiring-brocklesby-ltd-in-the-uk': '/article/st1-strengthens-waste-foodstock-business-by-acquiring-brocklesby-ltd-in-the-uk',
        '/st1-the-new-chair-of-lsb-eu-must-create-a-strong-2030-policy': '/about-us/news-and-media/company-and-press-releases',
        '/st1-to-construct-a-biogas-upgrading-and-liquefaction-refinery-in-sweden': '/about-us/news-and-media/company-and-press-releases',
        '/St1-to-expand-its-high-power-charging-network-for-electric-vehicles': '/about-us/sustainability/energy-transition/ev-charging-network',
        '/st1-to-launch-the-final-phase-of-drilling-the-worlds-deepest-geothermal-heat-wells-in-otaniemi': '/about-us/news-and-media/company-and-press-releases',
        '/st1-to-rebrand-its-network-of-shell-sites': '/about-us/news-and-media/company-and-press-releases',
        '/st1-to-sell-its-existing-wind-power-capapcity-in-finland-and-expand-its-wind-power-production-in-the-nordic-countries': '/about-us/news-and-media/company-and-press-releases',
        '/st1.com': '/',
        '/St1\'s_Otaniemi_geothermal_heating_plant_pilot_project_progresses_on_two_fronts': '/about-us/news-and-media/company-and-press-releases',
        '/St1\'s_Otaniemi_geothermal_heating_plant_pilot_project_progresses_to_a_flow_testphase': '/about-us/news-and-media/company-and-press-releases',
        '/st1s-and-soks-joint-venture-neb-plans-50-million-litre-cellunolix-bioethanol-pla': '/about-us/news-and-media/company-and-press-releases',
        '/St1s-ethanol-production-will-end-in-Vantaa-Lahti-and-Kajaani': '/about-us/news-and-media/company-and-press-releases',
        '/st1s-geothermal-heat-project-has-proceeded-as-planned': '/about-us/news-and-media/company-and-press-releases',
        '/st1s-geothermal-heating-project-approaching-pilot-plant-stage': '/about-us/news-and-media/company-and-press-releases',
        '/st1s-geothermal-pilot-project-in-otaniemi-espoo-progresses-to-the-finalization-of-the-heat-wells': '/about-us/news-and-media/company-and-press-releases',
        '/st1s-moroccan-afforestation-pilot-project-confirms-that-carbon-sinks-can-be-created-even-in-arid-conditions': '/about-us/sustainability/climate-and-nature/carbon-sequestration',
        '/st1s-otaniemi-geothermal-heating-plant-project-investigates-heat-production-methods': '/about-us/news-and-media/company-and-press-releases',
        '/st1s-otaniemi-geothermal-pilot-projects-deep-geothermal-wells-to-be-used-for-research': '/about-us/news-and-media/company-and-press-releases',
        '/St1s-P2X-project-in-Lappeenranta-Finland-will-not-proceed-on-its-original-schedule': '/about-us/news-and-media/company-and-press-releases',
        '/st1s-pilot-to-produce-advanced-ethanol-from-cassava-waste-starts-in-thailand': '/about-us/news-and-media/company-and-press-releases',
        '/st1s-service-station-network-strengthened-by-business-acquisition-in-norway': '/about-us/news-and-media/company-and-press-releases',
        '/st1s-technology-of-producing-ethanol-from-wood-residue-presented-in-Stockholm': '/about-us/news-and-media/company-and-press-releases',
        '/St1s-third-biogas-refuelling-point-for-heavy-traffic-opened-in-Halikko-Salo-Finland': '/about-us/news-and-media/company-and-press-releases',
        '/stationlocator': '/',
        '/stationlocator/': '/',
        '/stationlocator/map': '/',
        '/stationlocator/map/': '/',
        '/stationlocator/map/?___store=st1_website_eu_en': '/',
        '/stationlocator/map/index/query/st1-automaatti-kangasala-kt-921': '/',
        '/stimulation-phase-otaniemi-boreholes-proceeding-planned-st1-site': '/about-us/news-and-media/company-and-press-releases',
        '/stimulation-stage-began-at-a-geothermal-site-in-otaniemi': '/about-us/news-and-media/company-and-press-releases',
        '/Stimulation-stage-of-St1s-geothermal-project-successfully-completed': '/about-us/news-and-media/company-and-press-releases',
        '/stories': '/about-us/stories',
        '/stories-carbon-farming-can-be-applied-as-part-of-farm-production': '/about-us/stories',
        '/stories/1Vision-Biogas-is-set-to-become-a-leading-biogas-player-in-the-Nordics': '/about-us/news-and-media/company-and-press-releases#/embedded/release/70471894/1vision-biogas-is-set-to-become-a-leading-biogas-player-in-the-nordics?publisherId=69820571&lang=en&widget=true',
        '/stories/A-deep-dive-into-the-Davvi-planning-area': '/article/a-deep-dive-into-the-davvi-planning-area',
        '/stories/Afforestation-pilot-in-Morocco': '/about-us/stories',
        '/stories/Bringing-every-day-a-little-closer-to-perfect': '/about-us/stories',
        '/stories/Creating-business-value-with-IT-architecture': '/about-us/stories',
        '/Stories/Customer-growth-through-operational-excellence': '/about-us/stories',
        '/stories/Developing-our-due-diligence-practices': '/about-us/stories',
        '/stories/Energy-company-in-transition': '/about-us/stories',
        '/stories/Energy-trade-and-logistics': '/about-us/stories',
        '/stories/exploring-cutting-edge-carbon-dioxide-removal-technologies-in-the-negem-project': '/article/exploring-cutting-edge-carbon-dioxide-removal-technologies-in-the-negem',
        '/stories/Exploring-the-X': '/about-us/stories',
        '/stories/Fighting-the-arctic-elements': '/about-us/stories',
        '/stories/fostering-talents': '/about-us/stories',
        '/stories/HVO-value-chain-talent-program-nurtures-future-leaders': '/about-us/stories',
        '/stories/Investment-decision-taken-to-build-six-biogas-filling-stations': '/about-us/stories',
        '/stories/Investments-in-the-future': '/about-us/stories',
        '/stories/Leading-the-energy-transition-through-data': '/about-us/stories',
        '/stories/Looking-back-and-to-the-future': '/about-us/stories',
        '/stories/Making-an-impact-through-engagement-and-collaboration': '/about-us/stories',
        '/stories/Making-mass-EV-charging-a-reality': '/about-us/stories',
        '/stories/Making-wind-power-manageable': '/about-us/stories',
        '/stories/next-generation-terminal-technicians': '/about-us/stories',
        '/stories/Norwegian-Armed-Forces-creates-aviation-first-with-biofuels-from-St1': '/article/norwegian-armed-forces-creates-aviation-first-with-biofuels-from-st1',
        '/stories/Safety-first-at-St1-Refinery': '/about-us/stories',
        '/stories/Shaping-regulation-through-human-rights': '/about-us/stories',
        '/stories/Significant-Reductions-in-Heavy-Traffic-Emissions-Could-Be-Made-Now': '/article/significant-reductions-in-heavy-traffic-emissions-could-be-made-now',
        '/stories/St1-awarded-contract-to-build-new-energy-station': '/about-us/stories',
        '/stories/St1-launches-its-first-heavy-vehicle-charging-station': '/about-us/stories',
        '/stories/St1-Refinery-2030-project': '/about-us/stories',
        '/stories/St1-SCA-commences-production-of-renewable-fuels-in-Gothenburg': '/article/st1-and-scas-joint-venture-commences-the-production-of-renewable-fuels-in',
        '/stories/The-journey-from-gas-stations-to-energy-stations': '/about-us/stories',
        '/stories/The-strength-of-the-St1-brand-accelerates-business-growth': '/about-us/stories',
        '/stories/Trust-and-communication-support-growth': '/about-us/stories',
        '/stories/Wind-power-and-energy-efficiency': '/about-us/stories',
        '/stories/Working-at-the-forefront-of-the-energy-transition': '/about-us/stories',
        '/stories/Working-systematically-for-people-culture-and-nature': '/about-us/stories',
        '/Suomen-Lantakaasu-and-Atria-Finland-invest-in-biogas-plant-in-Nurmo': '/about-us/news-and-media/company-and-press-releases',
        '/Suomen-Lantakaasu-investing-more-than-EUR-80-million-in-Kiuruvesi-biogas-plant-project': '/about-us/news-and-media/company-and-press-releases',
        '/Suomen-Lantakaasu-Oy-acquires-Nurmon-Bioenergia': '/about-us/news-and-media/company-and-press-releases',
        '/Suomen-Lantakaasu-Oy-advancing-biogas-plant-investments-in-North-Ostrobothnia-Central-Ostrobothnia-and-South-Ostrobothnia': '/about-us/news-and-media/company-and-press-releases',
        '/suomen-lantakaasu-oy-moves-forward-with-the-development-of-two-new-biogas-plants-in-ostrobothnia': '/about-us/news-and-media/company-and-press-releases',
        '/suomen-lantakaasu-oy-to-start-groundworks-for-biogas-plant-in-kiuruvesi': '/about-us/news-and-media/company-and-press-releases',
        '/sustainability': '/about-us/sustainability',
        '/sustainability-at-St1': '/about-us/sustainability',
        '/Sustainability-training-program-launched-in-Finland': '/about-us/stories',
        '/sustainability/business-partner-expectations': '/about-us/sustainability',
        '/sustainability/coming-soon': '/about-us/sustainability',
        '/sustainability/ethical-business-principles': '/about-us/sustainability',
        '/sustainability/policies-principles': '/about-us/sustainability',
        '/sustainability/sustainability-framework': '/about-us/sustainability',
        '/sustainability/sustainable-carbon-cycle': '/about-us/sustainability/energy-transition',
        '/Sustainable-IT-accelerates-innovation': '/about-us/stories',
        '/sv/': '/',
        '/Taking-concrete-actions-to-fulfill-our-vision': '/about-us/stories',
        '/The-finalization-phase-of-St1\'s-Otaniemi-geothermal-heat-wells-finished': '/about-us/news-and-media/company-and-press-releases',
        '/The-finalization-phase-of-St1\'s-Otaniemi-geothermal-heat-wells-will-be-delayed-by-about-a-week': '/about-us/news-and-media/company-and-press-releases',
        '/the-necessity-of-a-sustainable-carbon-cycle': '/about-us/sustainability/energy-transition',
        '/the-norwegian-competition-authority-rejects-proposed-purchaser-of-st1-norge-as': '/about-us/news-and-media/company-and-press-releases',
        '/The-St1-monitoring-network-detected-1.2-magnitude-micro-earthquake': '/about-us/news-and-media/company-and-press-releases',
        '/The-St1-monitoring-network-detected-1.3-magnitude-micro-earthquake': '/about-us/news-and-media/company-and-press-releases',
        '/The-St1-monitoring-network-detected-1.6-magnitude-micro-earthquake': '/about-us/news-and-media/company-and-press-releases',
        '/the-st1-monitoring-network-detected-1.67-magnitudes-micro-earthquake': '/about-us/news-and-media/company-and-press-releases',
        '/The-stimulation-phase-of-Otaniemi-boreholes-has-proceeded-as-planned-already-over-halfway': '/about-us/news-and-media/company-and-press-releases',
        '/The-stimulation-phase-of-Otaniemi-boreholes-has-proceeded-as-planned-already-over-halfway,-a-little-bit-over-2-weeks-left': '/about-us/news-and-media/company-and-press-releases',
        '/The-stimulation-phase-of-St1-Otaniemi-geothermal-project-is-aimed-to-be-finished-by-the-end-of-the-week': '/about-us/news-and-media/company-and-press-releases',
        '/The-stimulation-phase-of-St1-Otaniemi-geothermal-project-is-aimed-to-be-finished-by-the-end-of-the-week,-read-the-project-update': '/about-us/news-and-media/company-and-press-releases',
        '/The-stimulation-stage-at-St1s-geothermal-project-site-employs-also-students': '/about-us/news-and-media/company-and-press-releases',
        '/theres-a-curve-we-all-should-be-watching': '/about-us/sustainability/energy-transition',
        '/tia-rytkonen': '/',
        '/tilinpaatostiedote-2018': '/about-us/company-info/key-figures-and-ir',
        '/tom-rinne-appointed-group-hr-director-in-st1-nordic-oy': '/about-us/news-and-media/company-and-press-releases',
        '/tuuliwatti-constructed-a-market-based-wind-farm-at-ii-viinamaki': '/about-us/news-and-media/company-and-press-releases',
        '/tuuliwatti-will-start-implementing-its-new-strategy-building-market-based-wind-farm-ii-viinamaki': '/about-us/news-and-media/company-and-press-releases',
        '/valio-and-st1-joint-venture-suomen-lantakaasu-oy-ready-to-increase-domestic-biogas-production': '/about-us/news-and-media/company-and-press-releases',
        '/valio-and-st1-to-produce-biogas-from-manure-a-new-opening-in-reducing-transport-emissions': '/about-us/news-and-media/company-and-press-releases',
        '/vantaa-energy-and-st1-to-cooperate-in-the-distribution-of-electric-fuel': '/about-us/news-and-media/company-and-press-releases',
        '/vattenfall-and-st1-form-a-new-partnership-to-produce-a-large-volume-of-fossil-free-aviation-electro-fuel-on-the-swedish-west-coast': '/about-us/news-and-media/company-and-press-releases',
        '/viobondconsortium': '/about-us/news-and-media/company-and-press-releases',
        '/VTT-and-St1-to-pilot-future-bioeconomy-Climate-friendly-hydrocarbons-from-industrial-carbon-dioxide-emission': '/about-us/news-and-media/company-and-press-releases',
        '/wartsila': '/about-us/news-and-media/company-and-press-releases',
        '/wartsila,-tieto,-st1,-fortum-and-demos-helsinki-launch-innovation-community-initiative-to-speed-clean-energy-targets"': '/about-us/news-and-media/company-and-press-releases',
        '/We_develop_geothermal_heat_technology_by_bedrock_research': '/about-us/news-and-media/company-and-press-releases',
        '/What-are-the-future-energy-solutions': '/about-us/stories',
        '/wind/tcm': '/about-us/sustainability/energy-transition/wind-power',
        '/www.capman.com': '/',
    },
    'no-NO': {
        '/privat': '/',
        '/Aneo-HitecVision-and-St1-join-forces': '/om-oss/stories',
        '/arets-koppe-bonanza-starter-na': '/artikkel/arets-koppe-bonanza-starter-na',
        '/bedrift/drivstoffkort': '/bedrift/bedrift-drivstoffkort',
        '/brumunddal': '/om-oss/stories',
        '/bytte-til-sommerdekk': '/om-oss/stories',
        '/chip-landing': '/om-oss/stories',
        '/derfor-bor-du-ha-kort-med-chip': '/om-oss/stories',
        '/dette-er-Shell-klett': '/om-oss/stories',
        '/drivstoff/bensin': '/privat/produkter/bensin',
        '/drivstoff/fordelsprogram-og-rabatter': '/privat/app-og-tilbud',
        '/en': '/',
        '/en/stationlocator': '/finn-stasjon',
        '/faq-norway': '/',
        '/for-bedrifter': '/bedrift',
        '/for-companies': '/bedrift',
        '/fornybarenergi': '/om-oss/baerekraft',
        '/fornybarenergi/drivstoff-fra-avfall': '/om-oss/baerekraft',
        '/fornybarenergi/geotermi': '/om-oss/baerekraft',
        '/fornybarenergi/karbonhosting': '/om-oss/baerekraft',
        '/fornybarenergi/vindkraft': '/om-oss/baerekraft/energiomstilling/vindkraft',
        '/fornybarenergi/vindkraft/sandfjelletvindpark': '/om-oss/baerekraft/energiomstilling/vindkraft',
        '/fremtid-for-flytende-drivstoff': '/artikkel/finnes-det-en-fremtid-for-flytende-drivstoff',
        '/Hurtiglading-hos-Shell': '/om-oss/stories',
        '/informasjon-til-allmenheten-ref-storulykkeforskriften-dsb': '/',
        '/Informasjon-til-kunder': '/om-oss/stories',
        '/jobb-og-karriere': '/om-oss/jobb-og-karriere',
        '/jobb-og-karriere/Shell': '/om-oss/jobb-og-karriere',
        '/jobb-og-karriere/st1-norge': '/om-oss/jobb-og-karriere',
        '/kaffeavtale': '/privat/tjenester/kaffeavtale',
        '/klett': '/',
        '/klima-trondheim': '/artikkel/et-bidrag-for-trondheims-klimasatsning',
        '/kontakt-oss': '/privat/kundeservice',
        '/kontakt-oss/mediehenvendelser': '/om-oss/for-presse',
        '/kontakt-oss/personvernerlaering': '/privat/personvernerlaering',
        '/kundens-program-vilkar-og-betingelser': '/privat/vilkar-for-lokale-kundeklubber',
        '/kundeservice': '/privat/kundeservice',
        '/lading-drivstoff/e10': '/',
        '/marine-depots': '/finn-stasjon',
        '/marine-og-bulk/energi@knapphus.no': '/',
        '/marine-og-bulk/produktinformasjon-og-betingelser': '/',
        '/marine-og-bulk/vare-olje-forhandlere': '/',
        '/marine-og-bulk/www.digerudvarme.no': '/',
        '/marine-og-bulk/www.knapphus.no': '/',
        '/node/606': '/',
        '/node/719': '/',
        '/node/873': '/',
        '/norgesvask/abonnement': '/privat/app-og-tilbud/abonnement',
        '/norgesvask/bilvask-pa-mobilen': '/privat/app-og-tilbud/bilvask-pa-mobil',
        '/norgesvask/norgesvask@st1.no': '/privat/tjenester/bilvask',
        '/norgesvask/singelvask': '/privat/app-og-tilbud/singelvask',
        '/norgesvask/sporsmal-og-svar': '/',
        '/Norske-Shellstasjoner-blir-omprofilert-til-St1': '/',
        '/nyheter/1Vision-Biogas-blir-ledende-biogassaktor-i-Norden': '/om-oss/stories',
        '/nyheter/Apnet-ny-energistasjon-for-fremtiden': '/om-oss/stories',
        '/nyheter/Dyrker-frem-egne-talenter': '/om-oss/stories',
        '/nyheter/E10': '/om-oss/stories',
        '/nyheter/Endringer-i-Biogass-Energi-AS': '/om-oss/stories',
        '/nyheter/Faar-42-millioner-til-flytende-biogassanlegg-i-norge': '/om-oss/stories',
        '/nyheter/Ferrari-odelagt-for-seg-selv': '/om-oss/stories',
        '/nyheter/Forsvaret-skaper-luftfartshistorie-med-biodrivstoff-fra-St1': '/om-oss/stories',
        '/nyheter/Fra-bensinstasjoner-til-energistasjoner': '/om-oss/stories',
        '/nyheter/fra-refuel-st1-stories': '/om-oss/stories',
        '/nyheter/Fremgang-i-planer-for-biogass-produksjonsanlegg': '/om-oss/stories',
        '/nyheter/Hater-du-vindkraft': '/om-oss/stories',
        '/nyheter/Her%20finner%20du%20oversikt%20over%20alle%20stasjoner%20med%20egne%20AdBlue%20fyllepunkter.': '/om-oss/stories',
        '/nyheter/hva-er-adblue-og-hvorfor-trenger-du-det': '/om-oss/stories',
        '/nyheter/Hva-er-et-drivstoffkort': '/om-oss/stories',
        '/nyheter/HVO-kan-redusere-co2-utslippet-med-60-prosent': '/om-oss/stories',
        '/nyheter/intention-to-consolidate': '/om-oss/stories',
        '/nyheter/iskald-logistikk': '/om-oss/stories',
        '/nyheter/jakten-paa-de-groenne-loesningene-i-tungtransporten': '/om-oss/stories',
        '/Nyheter/Kan-Dennis-Hauger-ende-i-Ferrari': '/om-oss/stories',
        '/nyheter/Laering-og-utvikling-gir-motivasjon': '/om-oss/stories',
        '/nyheter/lanserer-ny-app-det-har-aldri-vaert-enklere-a-vaske-bilen': '/om-oss/stories',
        '/nyheter/neste_generasjon_terminalteknikere': '/om-oss/stories',
        '/nyheter/Nordisk-storsatsing-pa-hurtiglading': '/om-oss/stories',
        '/nyheter/Ny-daglig-leder-i-Biogass-Energi': '/om-oss/stories',
        '/nyheter/ny-hamburger-fra-Shell': '/om-oss/stories',
        '/nyheter/Oliver-Solberg-olje-er-avgjorende': '/om-oss/stories',
        '/nyheter/oppbyggingen-av-nye-Shell-stasjonen-pa-Lonelier': '/om-oss/stories',
        '/nyheter/Oslo-Kommune-gir-stotte-til-Biogass-Energi-for-flytende-biogassanlegg-i-Oslo': '/om-oss/stories',
        '/nyheter/Pilot-bronner-for-geotermisk-energi-blir-overlatt-til-vitenskapelig-forskning': '/om-oss/stories',
        '/nyheter/Prat-med-Atle-Gulbrandsen': '/om-oss/stories',
        '/nyheter/samarbeid-om-distribusjon-av-syntetisk-drivstoff': '/om-oss/stories',
        '/nyheter/Satser-stort-pa-bioraffinering': '/om-oss/stories',
        '/nyheter/Shell-forlenger-med-Hyundai': '/om-oss/stories',
        '/nyheter/Shell-og-Formel-E': '/om-oss/stories',
        '/Nyheter/Slik-faar-du-mest-ut-av-drivstoffkortet': '/om-oss/stories',
        '/nyheter/slik-fyller-du-adblue': '/om-oss/stories',
        '/nyheter/sniktitt-ny-energistasjon': '/om-oss/stories',
        '/nyheter/spar-drivstoff-og-miljoet': '/om-oss/stories',
        '/nyheter/St1-aapner-storbillading-i-Oslo': '/om-oss/stories',
        '/nyheter/St1-ansetter-samfunnskontakt-for-Davvi-Vindpark': '/om-oss/stories',
        '/nyheter/St1-faar-bygge-energistasjon-ved-nye-E39': '/om-oss/stories',
        '/nyheter/St1-gar-videre-med-ammoniakkprosjekt': '/om-oss/stories',
        '/nyheter/St1-SCA-starter-produksjon-av-fornybart-drivstoff-Goteborg': '/om-oss/stories',
        '/nyheter/st1-skal-bygge-raffineri-for-oppgradering-av-biogass': '/om-oss/stories',
        '/nyheter/sykkelbane_ski': '/om-oss/stories',
        '/nyheter/Vindkraft-og-baerekraft': '/om-oss/stories',
        '/Nyheter/Vindkraft-og-energieffektivitet': '/om-oss/stories',
        '/nyheter/Vindkraft-skaper-arbeidsplasser': '/om-oss/stories',
        '/om-oss/vilkar-lokale-kundeklubber': '/privat/vilkar-for-lokale-kundeklubber',
        '/om-oss/www.st1.fi': 'https://st1.fi/yksityisille',
        '/om-oss/www.st1.no': '/om-oss',
        '/om-st1': '/om-oss',
        '/om-st1/firmaopplysninger-og-nokkeltall/firmaopplysninger-og-nokkeltall': '/om-oss/om-st1/nøkkeltall-og-ir',
        '/om-st1/selskapsinformasjon': '/om-oss/om-st1/nøkkeltall-og-ir',
        '/om-st1/st1-historie-no': '/om-oss/om-st1',
        '/personvern': '/om-oss/om-st1',
        '/personvernopplysninger-for-bedriftskunder': '/bedrift/personvernopplysninger-for-bedriftskunder',
        '/samarbeid-om-flytende-biodrivstoff': '/om-oss/stories',
        '/samarbeid-om-gronn-ammoniakk': '/om-oss/stories',
        '/ser-etter-tomt-i-Finnmark': '/om-oss/stories',
        '/Shell-Brumunddal-offisielt-apnet': '/om-oss/stories',
        '/shell-card': '/om-oss/stories',
        '/shell-stasjoner': '/privat',
        '/shell-stasjoner/adblue': '/privat/produkter',
        '/shell-stasjoner/baerekraftig-bilvask': '/privat/tjenester/bilvask',
        '/shell-stasjoner/bedriftskunder': '/bedrift',
        '/shell-stasjoner/bedriftskunder/fleetHub': '/bedrift/bedrift-drivstoffkort',
        '/shell-stasjoner/bedriftskunder/kundesupport': '/privat/kundeservice',
        '/shell-stasjoner/butikktilbud/bilvask': '/privat/tjenester/bilvask',
        '/shell-stasjoner/butikktilbud/real-mat': '/privat/tjenester/mat-og-drikke',
        '/shell-stasjoner/butikktilbud/real-mat/baguette': '/privat/tjenester/mat-og-drikke/real-baguette',
        '/shell-stasjoner/butikktilbud/real-mat/hamburger': '/privat/tjenester/mat-og-drikke/real-hamburger',
        '/shell-stasjoner/butikktilbud/snarveien-til-shell/snarveien-APP': '/privat/app-og-tilbud',
        '/shell-stasjoner/charge': '/privat/produkter/lading',
        '/shell-stasjoner/drivstoff/diesel': '/privat/produkter/diesel',
        '/shell-stasjoner/drivstoffkort': '/bedrift/bedrift-drivstoffkort',
        '/shell-stasjoner/drivstoffkort/bestille-og-sperre-kort': '/bedrift/bedrift-drivstoffkort',
        '/shell-stasjoner/drivstoffkort/bomlosning': '/bedrift/bedrift-drivstoffkort',
        '/shell-stasjoner/drivstoffkort/bomlosning-hjelp': '/bedrift/bedrift-drivstoffkort',
        '/shell-stasjoner/drivstoffkort/bomlosning/bestille-veiboks': '/bedrift/bedrift-drivstoffkort',
        '/shell-stasjoner/drivstoffkort/drivstoffpriser': '/bedrift/bedrift-drivstoffkort',
        '/shell-stasjoner/drivstoffkort/faktura': '/bedrift/bedrift-drivstoffkort',
        '/shell-stasjoner/drivstoffkort/kort-og-pin': '/bedrift/bedrift-drivstoffkort',
        '/shell-stasjoner/drivstoffkort/kort-og-pin/tips-til-ny-pin': '/bedrift/bedrift-drivstoffkort',
        '/shell-stasjoner/drivstoffkort/kundesupport': '/bedrift/bedrift-drivstoffkort',
        '/shell-stasjoner/drivstoffkort/rapporter': '/bedrift/bedrift-drivstoffkort',
        '/shell-stasjoner/drivstoffkort/Shell-fleet-hub': '/bedrift/bedrift-drivstoffkort',
        '/shell-stasjoner/drivstoffkort/takk-for-din-soknad': '/bedrift/bedrift-drivstoffkort',
        '/shell-stasjoner/kaffeavtale': '/privat/tjenester/kaffeavtale',
        '/shell-stasjoner/kaffeavtale/digital-kaffeavtale-kjop-i-app': '/privat/tjenester/kaffeavtale',
        '/shell-stasjoner/kaffeavtalen/digital-kaffeavtale': '/privat/tjenester/kaffeavtale',
        '/shell-stasjoner/lading/kundeservice': '/om-oss/om-st1/ta-kontakt',
        '/shell-stasjoner/norgesvask/app': '/',
        '/shell-stasjoner/tiltak-shell-gjor-hindre-smitte-av-koronavirus': '/',
        '/shell-stasjoner/tjenester': '/',
        '/shell-stasjoner/truckkort': '/',
        '/shell-stasjoner/vare-vaskeprogram': '/',
        '/shell-stasjoner/vis-hensyn': '/',
        '/shift': '/',
        '/shift/faq': '/',
        '/shift/fotavtrykk': '/',
        '/shift/fremtidige-muligheter': '/',
        '/shift/kenya': '/',
        '/shift/prosjekter': '/',
        '/shift/prosjektets-valgkriterier': '/',
        '/skal-produsere-biogass-av-husdyrgjodsel': '/',
        '/sliderhero': '/',
        '/slik-sparer-du-drivstoff': '/',
        '/st1-charge': '/',
        '/st1-charge/charging@st1.no': '/',
        '/st1-charge/hvordan-lade': '/',
        '/st1-charge/kundesupport': '/',
        '/st1-charge/lade-med-snarveien': '/',
        '/st1-charge/snarveien@st1.no': '/',
        '/St1-forsyner-norge-med-drivstoff': '/om-oss/stories',
        '/St1-Investerer-i-solenergi': '/om-oss/stories',
        '/St1-Norge-inn-i-biogass-markedet': '/om-oss/stories',
        '/st1finance/slik-tar-du-i-bruk-apple-wallet': '/',
        '/st1no/22665050': '/',
        '/st1no/brukervilkar-norgesvask': '/',
        '/st1no/bulk-og-terminaler': '/',
        '/st1no/endring-i-tilbud-og-vilkar': '/',
        '/st1no/info@st1.no': '/',
        '/st1no/nyheter': '/om-oss/stories',
        '/st1no/nyheter/pressemelding-st1-styrker-ravaretilgang-av-avfall-ved-oppkjop-av-brocklesby-ltd-i': '/om-oss/stories',
        '/st1no/om-oss': '/om-oss',
        '/st1no/om-oss/apenhetsloven': '/',
        '/st1no/om-oss/apenhetsloven@st1.no': '/privat/kundeservice',
        '/st1no/om-oss/kundeservice/apenhetsloven': '/privat/kundeservice',
        '/st1no/om-oss/presse': '/om-oss/for-presse',
        '/st1no/om-oss/vilkar-st1-kundeprogram': '/',
        '/st1no/om-oss/www.st1.no': '/',
        '/st1no/shell-stasjoner/bilvask': '/privat/tjenester/bilvask',
        '/st1no/shell-stasjoner/bilvask/klargjor-bilen': '/privat/tjenester/bilvask',
        '/st1no/shell-stasjoner/bilvask/klargjor-bilen?fbclid=IwAR3GQWy-Lu2GdHsuIUFENcoOkVsqxybJTatVaQ_Vc0EZUpLFAFVc_YqsaPo': '/privat/tjenester/bilvask',
        '/st1no/shell-stasjoner/bilvask/tips-klargjor-bilen': '/privat/tjenester/bilvask',
        '/st1no/shell-stasjoner/dine-apptilbud': '/',
        '/st1no/shell-stasjoner/farsdag': '/',
        '/st1no/shell-stasjoner/finn-shell-stasjon/kundeklubb': '/',
        '/st1no/shell-stasjoner/kaffeavtalen/kaffeavtale-pa-kopp': '/privat/tjenester/kaffeavtale',
        '/st1no/shell-stasjoner/koppjulekalender': '/privat/tjenester/kaffeavtale',
        '/st1no/shell-stasjoner/ladestasjoner': '/privat/produkter/lading',
        '/st1no/shell-stasjoner/lading-drivstoff': '/privat/produkter',
        '/st1no/shell-stasjoner/lading-drivstoff/charging': '/privat/produkter/lading',
        '/st1no/shell-stasjoner/lading-drivstoff/kundesupport-lading': '/',
        '/st1no/shell-stasjoner/last-ned-app': '/privat/app-og-tilbud',
        '/st1no/shell-stasjoner/nyt-sommeren-med-shell': '/',
        '/st1no/shell-stasjoner/real-mat': '/privat/tjenester/mat-og-drikke',
        '/st1no/shell-stasjoner/real-mat/bakeri': '/privat/tjenester/mat-og-drikke/bakeri',
        '/st1no/shell-stasjoner/real-mat/en-real-matpakke': '/privat/tjenester/mat-og-drikke/real-matpakke',
        '/st1no/shell-stasjoner/real-mat/meny': '/privat/tjenester/mat-og-drikke/meny',
        '/st1no/shell-stasjoner/real-mat/meny?embed=true': '/privat/tjenester/mat-og-drikke/meny',
        '/st1no/shell-stasjoner/real-mat/real-baguette': '/privat/tjenester/mat-og-drikke/real-baguette',
        '/st1no/shell-stasjoner/real-mat/real-matpakke': '/privat/tjenester/mat-og-drikke/real-matpakke',
        '/st1no/shell-stasjoner/real-mat/real-polse': '/privat/tjenester/mat-og-drikke/real-polse',
        '/st1no/shell-stasjoner/real-mat/sunnere-alternativer': '/privat/tjenester/mat-og-drikke/sunnere-alternativ',
        '/st1no/shell-stasjoner/shell-klett': '/',
        '/st1no/shell-stasjoner/shell-tipser': '/',
        '/st1no/shell-stasjoner/shell-tvedestrand': '/',
        '/st1no/shell-stasjoner/snarveien-til-shell/snarveien-appen-nye-kunder': '/privat/app-og-tilbud',
        '/st1no/shell-stasjoner/tjenester/st1-mastercard': '/',
        '/st1no/shell-stasjoner/v-power-nitro': '/privat/produkter/diesel',
        '/st1no/shell-stasjoner/vart-utvalg': '/',
        '/st1no/shell-stasjoner/vi-er-der-du-er-i-sommer': '/',
        '/st1no/shell-stasjoner/vi-setter-pris-pa-sommeren': '/',
        '/st1no/st1-ny': '/',
        '/st1no/st1/kontakt-oss/apenhetsloven@st1.no': '/privat/kundeservice',
        '/st1no/st1/kontakt-oss/euroshell@st1.no': '/privat/kundeservice',
        '/st1no/st1/kontakt-oss/kundeservice': '/privat/kundeservice',
        '/st1no/st1/kontakt-oss/presse': '/om-oss/for-presse',
        '/st1no/st1/st1-marine': '/bedrift/energiprodukter/marine-produkter',
        '/stasjoner': '/finn-stasjon',
        '/stasjoner/shell-aksdal': '/stasjoner/aksdal',
        '/stasjoner/shell-alvdal': '/stasjoner/alvdal',
        '/stasjoner/shell-as': '/stasjoner/as',
        '/stasjoner/shell-bardshaug': '/stasjoner/bardshaug',
        '/stasjoner/shell-bjolsen': '/stasjoner/bjlsen',
        '/stasjoner/shell-brakeroya': '/stasjoner/brakerya',
        '/stasjoner/shell-brumunddal': '/stasjoner/brumunddal',
        '/stasjoner/shell-brumunddal-e6': '/stasjoner/brumunddal',
        '/stasjoner/shell-bryne': '/stasjoner/bryne',
        '/stasjoner/shell-espa': '/stasjoner/espa',
        '/stasjoner/shell-express-furuset': '/stasjoner/express-furuset',
        '/stasjoner/shell-express-sandvika': '/stasjoner/express-sandvika',
        '/stasjoner/shell-fagernes': '/stasjoner/fagernes',
        '/stasjoner/shell-fauske': '/stasjoner/fauske',
        '/stasjoner/shell-grelland': '/stasjoner/grellan',
        '/stasjoner/shell-gudvangen': '/stasjoner/gudvangen',
        '/stasjoner/shell-hobol': '/stasjoner/hobol',
        '/stasjoner/shell-kastellet': '/stasjoner/kastellet',
        '/stasjoner/shell-kjelleveien': '/stasjoner/kjelleveien',
        '/stasjoner/shell-klett': '/stasjoner/klett',
        '/stasjoner/shell-knapphus': '/stasjoner/knapphus',
        '/stasjoner/shell-koppang': '/stasjoner/kopphang',
        '/stasjoner/shell-lasses': '/stasjoner/lasses',
        '/stasjoner/shell-lone': '/stasjoner/lone',
        '/stasjoner/shell-madlakrossen': '/stasjoner/madlakrossen',
        '/stasjoner/shell-mastemyr': '/stasjoner/mastemyr',
        '/stasjoner/shell-mjondalen': '/stasjoner/mjndalen',
        '/stasjoner/shell-moholt': '/stasjoner/moholt',
        '/stasjoner/shell-momarken': '/stasjoner/momarken',
        '/stasjoner/shell-morkved': '/stasjoner/mrkved',
        '/stasjoner/shell-mortensrud': '/stasjoner/mortensrud',
        '/stasjoner/shell-norheim': '/stasjoner/norheim',
        '/stasjoner/shell-rommen': '/stasjoner/rommen',
        '/stasjoner/shell-selje': '/stasjoner/selje',
        '/stasjoner/shell-sollibrua': '/stasjoner/sollibrua',
        '/stasjoner/shell-svinesund-e6': '/stasjoner/kjelleveien',
        '/stasjoner/shell-tvedestrand': '/stasjoner/svinesund-e6',
        '/stasjoner/shell-vige': '/stasjoner/vige',
        '/stasjoner/shell-vinger': '/stasjoner/vinger',
        '/stationlocator/map/index/query/shell-pori-koivula': '/finn-stasjon',
        '/stories/Investeringsbeslutning-tatt-for-a-bygge-seks-biogass-fyllestasjoner': '/om-oss/stories',
        '/stories/Vattenfall-og-St1-etablerer-partnerskap-for-produsere-fossilfritt-elektrodrivstoff': '/om-oss/stories',
        '/stories/Vi-utforsker-Power-to-X': '/om-oss/stories',
        '/test-biogass': '/',
        '/test-form-no': '/',
        '/test-iframe': '/',
        '/Test/cardtab': '/',
        '/vare-depoter': '/',
        '/vilkar-snarveien-app': '/privat/bruksvilkar-for-st1-digitale-kanaler-for-forbrukere',
        '/st1no/shell-stasjoner/norgesvask': '/privat/tjenester/bilvask',
        '/b2b': '/bedrift/b2b',
    },
    'sv-SE': {
        '/privat': '/',
        '/1Vision-biogas-har-erhallit-erforderliga-tillstand': '/om-oss/nyheter-och-press',
        '/1VisionBiogas-ska-bli-en-ledande': '/om-oss/nyheter-och-press',
        '/2030': '/om-oss',
        '/allmannavillkor': '/privat/allmannavillkor',
        '/Aneo-HitecVision-and-St1-join-forces': '/om-oss/nyheter-och-press',
        '/appenformular': '/',
        '/apple-pay-gors-nu-tillgangligt-st1s-kunder': '/om-oss/nyheter-och-press',
        '/arets-godaste-samarbeten-prisade': '/om-oss/nyheter-och-press',
        '/arnese': '/',
        '/b2b': '/foretag/b2b',
        '/bensin95-e10': '/privat/produkter/bensin',
        '/billig-bensin': '/privat/app-och-erbjudanden/billig-bensin',
        '/biogas': '/foretag/energiprodukter/foretag-biogas',
        '/biogas/gronamobilister': '/',
        '/bioraffinaderi': '/om-oss/foretagsinformation/verksamhetsomraden/st1-raffinaderi',
        '/Biorefinery-Ostrand-ingar-EU-avtal': '/om-oss/nyheter-och-press',
        '/butikssajare-natt-och-kvallsskift-jonkoping': '/',
        '/butikssaljare-deltid-motala': '/',
        '/butikssaljare-deltid-saro': '/',
        '/butikssaljare-deltid-skovde': '/',
        '/dataprivacy@st1.fi': '/',
        '/dataprivacy@st1.no': '/',
        '/dataprivacy@st1.se': '/',
        '/drifttekniker-till-st1s-depa-i-lulea': '/',
        '/drivingtheenergytransition': '/om-oss',
        '/e-post-med-gamla-kvitton': '/',
        '/Ebba-busch-invigde-bioraffinaderi': '/om-oss/nyheter-och-press',
        '/email': '/',
        '/en/': '/',
        '/en/dataprivacy@st1.fi': '/',
        '/en/dataprivacy@st1.no': '/',
        '/en/dataprivacy@st1.se': '/',
        '/en/general_terms_and_conditions': '/allmanna-villkor',
        '/en/privacy_policy': '/integritetspolicy',
        '/en/stationlocator': '/hitta-station',
        '/energifyllt-samarbete-mellan-St1-och-Boras-Energi-och-Miljo': '/om-oss/nyheter-och-press',
        '/engagerad-areainspektor-sokes-till-st1-refinery-ab': '/',
        '/exempel-pa-karriarer': '/',
        '/exempel-pa-karriarer-0': '/',
        '/fakturainformation-leverantor': '/allmanna-villkor',
        '/FAQ-tillgodohavande': '/',
        '/foretag/biogas': '/foretag/energiprodukter/foretag-biogas',
        '/foretag/bulk': '/foretag/foretag-tjanster/bulk',
        '/foretag/businesskort': '/foretag/st1-business-tjanst/st1-business-kort',
        '/foretag/businesskort/PIN-kod': '/foretag/st1-business-tjanst/st1-business-mina-sidor',
        '/foretag/foretagskort': '/foretag/st1-business-tjanst/st1-business-kort',
        '/foretag/hitta-station': '/hitta-station',
        '/foretag/koppla-kort': '/foretag/st1-business-tjanst/st1-business-mina-sidor',
        '/foretag/koppla-mina-sidor': '/foretag/st1-business-tjanst/st1-business-mina-sidor',
        '/foretag/kundservice': '/foretag/kundservice-foretag',
        '/foretag/latt-yrkestrafik': '/foretag/foretag-tjanster/latt-yrkestrafik',
        '/foretag/marin': '/foretag/foretag-tjanster/marin',
        '/foretag/marin/marine': '/foretag/foretag-tjanster/marin',
        '/foretag/marin/produkter': '/foretag/foretag-tjanster/marin',
        '/foretag/st1-business-kort': '/foretag/st1-business-tjanst/st1-business-kort',
        '/foretag/truck': '/foretag/foretag-tjanster/tung-yrkestrafik',
        '/foretag/tung-trafik': '/foretag/foretag-tjanster/tung-yrkestrafik',
        '/fornybar-energi': '/om-oss/hallbarhet',
        '/forsaljningsansvarig-region-foretagskort': '/',
        '/fuelpay': '/',
        '/gemensamt-pressmeddelande-fran-st1-och-sca': '/om-oss/nyheter-och-press',
        '/gronfargad-diesel': '/',
        '/hitta-station/shell-truckdiesel-arlandastad': '/hitta-station',
        '/hitta-station/shell-truckdiesel-hudiksvall': '/hitta-station',
        '/hitta-station/shell-truckdiesel-mora': '/hitta-station',
        '/hitta-station/shell-truckdiesel-vasteras-volvo': '/hitta-station',
        '/hitta-station/st1-asa-varbergsvagen': '/hitta-station',
        '/hitta-station/st1-biogas-varnamo': '/hitta-station',
        '/hitta-station/st1-fagersta-floravagen': '/hitta-station',
        '/hitta-station/st1-frihamnen-stockholm': '/hitta-station',
        '/hitta-station/st1-hoganas': '/hitta-station',
        '/hitta-station/st1-jomala-kantarellen': '/hitta-station',
        '/hitta-station/st1-jomala-sparmacken': '/hitta-station',
        '/hitta-station/st1-norsborg-alby': '/hitta-station',
        '/hitta-station/st1-ronneby': '/hitta-station',
        '/hitta-station/st1-ronneby-kallinge': '/hitta-station',
        '/hitta-station/st1-smabatsvarvet': '/hitta-station',
        '/hitta-station/st1-spanga-krallingegrand': '/hitta-station',
        '/hitta-station/st1-taby-enhagsv': '/hitta-station',
        '/hitta-station/st1-truck-vara-fordonsgatan': '/hitta-station',
        '/hitta-station/st1-vallingby-kirunagatan': '/hitta-station',
        '/hitta-station/st1-varnamo-malmovagen': '/hitta-station',
        '/hitta-station/st1-ystad-spaljegatan': '/hitta-station',
        '/ingenjor-inom-el-och-automation': '/',
        '/ingenjorspecialist-inom-roterande-utrustning': '/',
        '/instrumenttekniker': '/',
        '/integritetspolicy': '/privat/integritetspolicy',
        '/integritetspolicy_foretagskunder': '/foretag/integritetspolicy_foretagskunder',
        '/kameraovervakning': '/kameraoevervakning',
        '/kampanj-vackrare': '/',
        '/kontobestammelser': '/allmanna-villkor',
        '/krav-fran-visa-och-mastercard': '/',
        '/kundservice/vanliga-fragor-och-svar': '/privat/kundservice/vanliga-fragor-och-svar',
        '/kundservice/vanliga-fragor-svar': '/privat/kundservice/vanliga-fragor-och-svar',
        '/kundserviceform': '/privat/kundservice',
        '/lediga-jobb/senior-process-engineer': '/',
        '/lediga-jobb/skotselforetag-st1-stationer-sodra_stockholm': '/',
        '/lediga-jobb/supply-chain-solutions-specialist': '/',
        '/ledningsanvisning': '/privat/produkter/privat-biogas',
        '/leverans-och-logistik': '/om-oss/foretagsinformation/verksamhetsomraden',
        '/loading-master-till-st1-refinery-ab': '/',
        '/Mattias.Wesslau@nordionenergi.se': '/',
        '/medarbetare-karriar': '/om-oss/medarbetare-och-karriar',
        '/mer-om-fuelpay': '/',
        '/miika-eerola-utsedd-till-vd-st1-refinery-ab': '/om-oss/nyheter-och-press',
        '/mikael-oberg': '/',
        '/miljoinfo': '/privat/produkter/miljoinformation',
        '/minskad-klimatbelastning-och-sakrare-transporter-avgorande-fortsatt-samarbete-mellan-st1-och-green': '/om-oss/nyheter-och-press',
        '/mobility': '/privat/app-och-erbjudanden/st1-mobility',
        '/Nordion-Energi-och-St1-i-samarbete-om-flytande-biogas': '/om-oss/nyheter-och-press',
        '/north-european-oil-trade-oy': '/om-oss/foretagsinformation/associerade-foretag',
        '/nu-oppnar-vi-vara-forsta-St1-Truck': '/om-oss/nyheter-och-press',
        '/ny-biogasanlaggning-tillsammans-med-valio': '/om-oss/nyheter-och-press',
        '/ny-musiktjanst-sound-driving': '/om-oss/nyheter-och-press',
        '/ny-st1-biogasstation-i-strangnas': '/om-oss/nyheter-och-press',
        '/nytt-varldsrekord-pa-st1s-geotermiska-borrplats': '/om-oss/nyheter-och-press',
        '/om-gasledningar': '/privat/produkter/privat-biogas',
        '/om-ledningsanvisning': '/privat/produkter/privat-biogas',
        '/om-man-inte-vet-vart-man-ska-sa-spelar-det-ingen-roll-vilken-vag-man-tar': '/om-oss/nyheter-och-press',
        '/om-st1': '/om-oss',
        '/om-st1/foretagsinformation': '/om-oss',
        '/om-st1/foretagsinformation/foretagsinformation/associerade-foretag': '/om-oss/foretagsinformation/associerade-foretag',
        '/om-st1/foretagsinformation/foretagsinformation/code-of-conduct': '/om-oss/hallbarhet',
        '/om-st1/foretagsinformation/foretagsinformation/foretagsinformation-och-nyckeltal': '/om-oss/foretagsinformation/nyckeltal-och-ir',
        '/om-st1/foretagsinformation/foretagsinformation/nyheter-och-press/pressmeddelanden': '/om-oss/nyheter-och-press',
        '/om-st1/foretagsinformation/foretagsinformation/var-historia': '/om-oss/foretagsinformation',
        '/om-st1/foretagsinformation/foretagsinformation/vara-nordiska-hemsidor': '/om-oss/foretagsinformation',
        '/om-st1/foretagsinformation/foretagsinformation/vi-pa-st1': '/om-oss/foretagsinformation/kontaktinformation',
        '/om-st1/foretagsinformation/forskning-och-utveckling': '/om-oss',
        '/om-st1/foretagsinformation/forskning-och-utveckling/framtidens-energilosningar': '/om-oss/hallbarhet/energiomstallning/fardplan-for-energiomstallningen',
        '/om-st1/foretagsinformation/vara-reklamkampanjer': '/privat/app-och-erbjudanden/kampanj',
        '/om-st1/foretagsinformation/vara-reklamkampanjer/tidigare-kampanjer': '/privat/app-och-erbjudanden/kampanj',
        '/om-st1/foretagsinformation/verksamhetsomraden': '/om-oss/foretagsinformation/verksamhetsomraden',
        '/om-st1/foretagsinformation/verksamhetsomraden/avancerade-branslen-fran-avfall': '/om-oss/foretagsinformation/verksamhetsomraden',
        '/om-st1/foretagsinformation/verksamhetsomraden/direktforsaljning': '/om-oss/foretagsinformation/verksamhetsomraden',
        '/om-st1/foretagsinformation/verksamhetsomraden/nordiskt-stationsnatverk': '/om-oss/foretagsinformation/verksamhetsomraden',
        '/om-st1/foretagsinformation/verksamhetsomraden/raffinaderiet-i-goteborg': '/om-oss/foretagsinformation/verksamhetsomraden',
        '/om-st1/foretagsinformation/verksamhetsomraden/smarta-branslen': '/om-oss/foretagsinformation/verksamhetsomraden',
        '/om-st1/foretagsinformation/verksamhetsomraden/supply-och-logistik': '/om-oss/foretagsinformation/verksamhetsomraden',
        '/om-st1/fornybar-energi/1st-renewable-pa-vag-mot-en-fossiloberoende-framtid': '/',
        '/om-st1/fornybar-energi/carbon-farming': 'https://st1.com/about-us/sustainability/climate-and-nature/carbon-sequestration',
        '/om-st1/fornybar-energi/framstallning': '/om-oss',
        '/om-st1/jobba-pa-st1/processakerhetsingenjor-till-st1-refinery': '/',
        '/om-st1/medarbetare-karriar/jobba-pa-st1/bli-franchisetagare': '/',
        '/om-st1/medarbetare-karriar/jobba-pa-st1/lediga-tjanster': '/om-oss/medarbetare-och-karriar',
        '/om-st1/nyheter-och-press': '/om-oss/nyheter-och-press',
        '/om-st1/raffinaderiet/life-och-etanolix-20': '/om-oss',
        '/om-st1/raffinaderiet/life-och-etanolix-20/life-english': '/om-oss',
        '/om-st1/st1-outlook': '/om-oss',
        '/om-st1/var-vision': '/om-oss/foretagsinformation/vision-och-varderingar',
        '/om-st1/vision/st1s-vardekedja': '/om-oss',
        '/om-st1/vision/vision/avancerade-biodrivmedel': '/om-oss',
        '/oppna-mojligheter': '/om-oss/medarbetare-och-karriar',
        '/pdf': '/',
        '/personuppgifter-och-cookies': '/',
        '/pilotprojekt-overlamnas-till-forskning': '/om-oss/nyheter-och-press',
        '/press': '/om-oss/nyheter-och-press',
        '/presskonferens': '/om-oss/nyheter-och-press',
        '/pressrelease-st1-avtal-med-kundloftesvard-vitlistat': '/om-oss/nyheter-och-press',
        '/privat/betalningslosningar': '/privat/app-och-erbjudanden/st1-mobility',
        '/privat/betalningslosningar/vara-kort/st1-cashkort': '/privat/app-och-erbjudanden/st1-mobility',
        '/privat/betalningslosningar/vara-kort/st1-privatkort': '/privat/app-och-erbjudanden/st1-mobility',
        '/privat/drivmedel': '/privat/produkter',
        '/privat/drivmedel/bra-att-veta/produkt-och-sakerhetsdatablad': '/produkt-och-sakerhetsdatablad',
        '/privat/drivmedel/bra-att-veta/vinter-och-sommardrivmedel': '/privat/produkter',
        '/privat/drivmedel/drivmedel/bensin': '/privat/produkter/bensin',
        '/privat/drivmedel/drivmedel/biogas': '/privat/produkter/privat-biogas',
        '/privat/drivmedel/drivmedel/diesel': '/privat/produkter/diesel',
        '/privat/drivmedel/drivmedel/e85': '/privat/produkter/e85',
        '/privat/drivmedel/drivmedel/e85-fordonsgas': '/privat/produkter/privat-biogas',
        '/privat/drivmedel/drivmedel/fordonsgas': '/privat/produkter/privat-biogas',
        '/privat/drivmedel/drivmedel/ny-produktmarkning': '/foretag/energiprodukter/miljoinformation',
        '/privat/kundservice/eco-driving': '/',
        '/privat/kundservice/hitta-station': '/hitta-station',
        '/privat/kundservice/kundservice/blanketter': '/privat/kundservice',
        '/privat/kundservice/kundservice/vanliga-fragor': '/privat/kundservice/vanliga-fragor-och-svar',
        '/privat/kundservice/lediga-jobb': '/om-oss/medarbetare-och-karriar',
        '/privat/kundservice/vara-kort/st1-cashkort': '/privat/app-och-erbjudanden/st1-mobility',
        '/privat/kundservice/vara-kort/st1-mastercard': '/privat/app-och-erbjudanden/st1-mobility',
        '/privat/kundservice/vara-kort/st1-privatkort': '/privat/app-och-erbjudanden/st1-mobility',
        '/privat/st1-foretagskort': '/foretag/st1-business-tjanst/st1-business-kort',
        '/privat/vara-stationer/bra-att-veta': '/privat/produkter',
        '/privat/vara-stationer/bra-att-veta/ursprungsinformation': '/foretag/energiprodukter/miljoinformation',
        '/privat/vara-stationer/bra-att-veta/vinter-och-sommardrivmedel': '/privat/produkter',
        '/privat/vara-stationer/drivmedel/diesel': '/privat/produkter/diesel',
        '/privat/vara-stationer/drivmedel/laddstation-elbil': '/privat/produkter/ladda-bilen',
        '/privat/vara-stationer/drivmedel/st1-charge': '/privat/produkter/ladda-bilen',
        '/privat/vara-stationer/drivmedel/st1-charge/medfinansiering': '/privat/produkter/ladda-bilen',
        '/projektingenjor': '/',
        '/projektingenjorprojektledare': '/',
        '/raffinaderiet': '/om-oss/foretagsinformation/verksamhetsomraden/st1-raffinaderi',
        '/registrerakort': '/foretag/st1-business-tjanst/st1-business-mina-sidor',
        '/saila.horttanainen@nordionenergi.se': '/',
        '/sakrare_id_St1appen': '/',
        '/sakrare_id_St1mastercard': '/',
        '/servicetekniker-fastighetsskotsel-etanolframstallning': '/',
        '/sex-av-tio-svenskar-positiva-till-ett-samhalle-utan-kontanter': '/om-oss/nyheter-och-press',
        '/shell-satter-stopp-tjuvtankningarna': '/om-oss/nyheter-och-press',
        '/shellstationerna-skyltas-om-till-st1': '/om-oss/nyheter-och-press',
        '/shift': '/privat',
        '/shift/beskogningsprojekt-kenya': '/',
        '/shift/faq': '/',
        '/shift/fotavtryck': '/',
        '/shift/framtida-mojligheter': '/',
        '/shift/projekt': '/',
        '/shift/projekt-urvalskriterier': '/',
        '/shift/senaste-nytt': '/',
        '/skicka-ett-meddelande': '/',
        '/skotselforetag-halsingland': '/',
        '/solceller-pa-bensinstationer': '/om-oss/nyheter-och-press',
        '/spontanansokan': '/om-oss/medarbetare-och-karriar',
        '/st1_nordic_oy_financial_statements_release_2018': '/om-oss/nyheter-och-press',
        '/st1-appen-fuelpay': '/',
        '/st1-bransleforsorjningskedja': '/om-oss/nyheter-och-press',
        '/st1-brocklesby': '/om-oss/nyheter-och-press',
        '/St1-bygger-bioraffinaderi-fornybar-diesel-och-flygbransle': '/om-oss/nyheter-och-press',
        '/st1-bygger-blixtsnabba-laddare-for-elbilar': '/om-oss/nyheter-och-press',
        '/st1-charge': '/privat/produkter/ladda-bilen',
        '/st1-charge/customer-service': '/privat/produkter/ladda-bilen',
        '/st1-charge/how-to-charge': '/privat/produkter/ladda-bilen',
        '/st1-forvarvar-eon-biofor': '/om-oss/nyheter-och-press',
        '/st1-group-oy-merged-st1-nordic-oy': '/om-oss/nyheter-och-press',
        '/st1-har-oppnat-biogasstationer-for-tung-trafik-i-finska-staderna-iittala-och-mantsala': '/om-oss/nyheter-och-press',
        '/st1-investerar-i-biogas-i-sverige': '/om-oss/nyheter-och-press',
        '/St1-investerar-i-solceller': '/om-oss/nyheter-och-press',
        '/st1-lanserar-framtidens-butik-och-biltvattskedja-manniskor-pa-vag-valkommen': '/om-oss/nyheter-och-press',
        '/st1-lanserar-ny-mobilapp': '/om-oss/nyheter-och-press',
        '/st1-leverantorstraff-2024': '/om-oss/nyheter-och-press',
        '/St1-och-Horisont-Energi': '/om-oss/nyheter-och-press',
        '/St1-oppnar-ny-biogasanlaggning-i-Boras': '/om-oss/nyheter-och-press',
        '/St1-planerar-solceller-Dalarna': '/om-oss/nyheter-och-press',
        '/st1-plans-extend-its-advanced-biofuels-production-renewable-diesel': '/om-oss/nyheter-och-press',
        '/st1-pressbilder': '/om-oss/nyheter-och-press',
        '/st1-sca-biodrivmedel': '/om-oss/nyheter-och-press',
        '/st1-soker-skotselforetag-till-vara-automatstationer': '/om-oss/medarbetare-och-karriar',
        '/St1-startar-biogasverksamhet-i-Sverige': '/om-oss/nyheter-och-press',
        '/st1-startar-pilotprojekt-koldioxidlagring-genom-skogsplantering-i-marocko': '/om-oss/nyheter-och-press',
        '/st1-storsatsar-pa-branschens-forsta-hogkvalitativa-kaffe': '/om-oss/nyheter-och-press',
        '/st1-tecknar-avtal-med-bildelskedjan-mekonomen-group': '/om-oss/nyheter-och-press',
        '/st1-tecknar-avtal-med-menigo-helhetsleverantor-inom-mat-och-dryck': '/om-oss/nyheter-och-press',
        '/st1-truck-fortsatter-att-vaxa': '/om-oss/nyheter-och-press',
        '/st1-utokar-sin-verksamhet-med-betalningstjanster-inom-finanssektorn': '/om-oss/nyheter-och-press',
        '/st1-valjer-visma-retail-offensiv-satsning-pa-butiksverksamhet': '/om-oss/nyheter-och-press',
        '/st1-vantaa-energy-and-St1-in-cooperation': '/om-oss/nyheter-och-press',
        '/St1-vaxer-med-20-nya-anlaggningar-for-tung-trafik': '/om-oss/nyheter-och-press',
        '/st1biokraft': 'www.st1biokraft.com',
        '/st1s_allmanna_villkor_for_webbportaler_for_foretagskunder': '/foretag/st1s-allmanna-villkor-for-webbportaler-for-foretagskunder',
        '/stationlocator/map/index/query/shell-jonkoping-sjukhusg-a6': '/hitta-station',
        '/stationskampanj': '/privat',
        '/tanka': '/privat',
        '/test-page-se': '/',
        '/underhallstekniker-till-st1-refinery': '/',
        '/vagval': '/om-oss',
        '/valio-st1-biogas': '/om-oss/nyheter-och-press',
        '/valkommen-vid-shell-satsar-pa-engangsgrillar-av-tra-och-papper': '/om-oss/nyheter-och-press',
        '/vattenfall-och-st1-i-nytt-fossifritt-samarbete': '/om-oss/nyheter-och-press',
        '/vi-borrar-djupare-en-battre-miljo': '/om-oss/nyheter-och-press',
        '/viktiginfo': '/privat',
        '/viktiginformation': '/privat',
        '/vind': '/om-oss/foretagsinformation/verksamhetsomraden',
        '/vindkraft': '/om-oss/foretagsinformation/verksamhetsomraden',
        '/vindkraft/gullberget': '/om-oss/foretagsinformation/verksamhetsomraden',
        '/vindkraft/tcm': '/om-oss/foretagsinformation/verksamhetsomraden',
        '/www.st1.com': '/privat',
        '/www.st1.se': '/privat',
    },
};

export default defineNuxtRouteMiddleware(route => {
    const query = Object.keys(route.query).map(key => `${key}=${route.query[key]}`).join('&');
    const { $i18n } = useNuxtApp();
    const url = useRequestURL();

    if (route.path.match(/^.+\/$/) !== null || url.host.match(/^www\./) !== null) {
        return navigateTo(
            `${url.protocol}//${url.host.replace(/^www\./, '')}${route.path.replace(/\/$/, '')}`,
            {
                external: true,
                redirectCode: httpStatus.TEMPORARY_REDIRECT,
            },
        );
    }

    if (import.meta.server && Object.keys(redirects[$i18n.locale.value]).includes(route.path)) {
        return navigateTo(
            `${redirects[$i18n.locale.value][route.path]}${query.length > 0 ? `?${query}` : ''}`,
            {
                external: true,
                redirectCode: httpStatus.TEMPORARY_REDIRECT,
            },
        );
    }

    if (Object.keys(redirects[$i18n.locale.value]).includes(route.path)) {
        const currentDomain = `${url.protocol}//${url.host}`;
        const isExternal = redirects[$i18n.locale.value][route.path].match(/^(http|https):\/\//) !== null;

        return navigateTo(
            `${redirects[$i18n.locale.value][route.path].replace(currentDomain, '')}${query.length > 0 ? `?${query}` : ''}`,
            {
                external: isExternal,
                redirectCode: httpStatus.TEMPORARY_REDIRECT,
            },
        );
    }
});
