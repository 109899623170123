import { usePageStore } from '~dsc/stores/cms/page';
import { find } from 'lodash-es/collection';
import { defineNuxtRouteMiddleware, navigateTo, storeToRefs } from '#imports';
import { httpStatus } from '~dsc/utils/http-status';
import locales from '@@/configuration/locales';

const devDomains = {
    'https://test.st1.fi': locales.find(item => item.code === 'fi-FI')?.domain,
    'https://test.st1.se': locales.find(item => item.code === 'se-SE')?.domain,
    'https://test.st1.no': locales.find(item => item.code === 'no-NO')?.domain,
    'https://test.st1.com': locales.find(item => item.code === 'en-EU')?.domain,
    'https://stage.st1.fi': locales.find(item => item.code === 'fi-FI')?.domain,
    'https://stage.st1.se': locales.find(item => item.code === 'se-SE')?.domain,
    'https://stage.st1.no': locales.find(item => item.code === 'no-NO')?.domain,
    'https://stage.st1.com': locales.find(item => item.code === 'en-EU')?.domain,
    'https://st1.fi': locales.find(item => item.code === 'fi-FI')?.domain,
    'https://st1.se': locales.find(item => item.code === 'se-SE')?.domain,
    'https://st1.no': locales.find(item => item.code === 'no-NO')?.domain,
    'https://st1.com': locales.find(item => item.code === 'en-EU')?.domain,
};

export default defineNuxtRouteMiddleware(() => {
    const { pageBuffer, pageData } = storeToRefs(usePageStore());
    const currentData = import.meta.client ? pageBuffer : pageData;
    const redirect = find(currentData.value, (part => part.contentType === 'cardList' && part?.display?.component === 'redirect'));

    if (currentData.value && redirect) {
        let { url } = redirect.display;

        if (import.meta.dev) {
            Object.keys(devDomains).forEach(domain => {
                url = url.replace(domain, devDomains[domain]);
            });
        }

        if (redirect) {
            return navigateTo(
                url,
                {
                    external: redirect.display.url.match(/^http/) !== null,
                    redirectCode: httpStatus.TEMPORARY_REDIRECT,
                },
            );
        }
    }
});
