import { defineNuxtPlugin } from '#imports';
import configPaths from '@@/configuration/paths.json';

const paths = {
    landingPage: {
        'fi-FI': '/',
        'en-EU': '/',
        'sv-SE': '/',
        'no-NO': '/',
    },
    stationLocator: {
        'en-EU': '/stationsearch',
        'fi-FI': '/asemahaku',
        'sv-SE': '/hitta-station',
        'no-NO': '/finn-stasjon',
    },
    b2b: {
        'en-EU': '/company',
        'fi-FI': '/yrityksille',
        'sv-SE': '/foretag',
        'no-NO': '/befrift',
    },
    terms: {
        'fi-FI': '/kayttoehdot',
        'en-EU': '/terms',
        'sv-SE': '/allmanna-villkor',
        'no-NO': '/vilkar-for-bruk',
    },
    service: {
        'fi-FI': '?service=MAIN',
        'en-EU': '?service=MAIN',
    },
    contact: {
        'fi-FI': '/yksityisille/ota-yhteytta/asiakaspalvelu',
        'en-EU': '/about-us/company-info/contact-us',
        'sv-SE': '/privat/kundservice',
        'no-NO': '/privat/kundservice',
    },
    articles: {
        'en-EU': '/article',
        'fi-FI': '/artikkeli',
        'sv-SE': '/artikel',
        'no-NO': '/artikkel',
    },
    ...configPaths,
};

const endpoints = {
    layout: 'cms-view/v1/content/layout',
    page: 'cms-view/v1/content/page',
    order: 'digital-order/v1/orders/initialize',
    complete: 'digital-order/v1/orders/complete',
    customer: 'b2c-my-pages-view-v2/v1/customer/details',
    cards: 'b2c-my-pages-view-v2/v1/customer/card/emv/list',
    search: 'cms-view/v1/search',
};

export { paths };
export { endpoints };

export default defineNuxtPlugin(nuxtApp => {
    const { $i18n } = nuxtApp;

    return {
        provide: {
            paths: {
                getPath(key) {
                    try {
                        if (paths[key][$i18n.locale.value]) {
                            return paths[key][$i18n.locale.value];
                        }
                    } catch (e) {
                        if (process.env.NODE_ENV === 'development') {
                            console.warn(`No path found in paths plugin for ${key}`);
                        }

                        return '/';
                    }

                    if (paths[key][$i18n.locale.value]) {
                        return paths[key][$i18n.locale.value];
                    }

                    if (process.env.NODE_ENV === 'development') {
                        console.warn(`No path found in paths plugin for ${key}`);
                    }

                    return '/';
                },
                getEndpoint(alias) {
                    if (endpoints[alias]) {
                        return endpoints[alias];
                    }

                    return '';
                },
            },
        },
    };
});
