
// @ts-nocheck
import locale___nuxt_dsc_locales_fi_FI_json from "../../.nuxt/dsc/locales/fi-FI.json";
import locale__service_src_locales_fi_FI_js from "../src/locales/fi-FI.js";
import locale___nuxt_dsc_locales_en_EU_json from "../../.nuxt/dsc/locales/en-EU.json";
import locale__service_src_locales_en_EU_js from "../src/locales/en-EU.js";
import locale___nuxt_dsc_locales_sv_SE_json from "../../.nuxt/dsc/locales/sv-SE.json";
import locale__service_src_locales_sv_SE_js from "../src/locales/sv-SE.js";
import locale__service_src_locales_no_NO_js from "../src/locales/no-NO.js";


export const localeCodes =  [
  "fi-FI",
  "en-EU",
  "sv-SE",
  "no-NO"
]

export const localeLoaders = {
  "fi-FI": [{ key: "../../.nuxt/dsc/locales/fi-FI.json", load: () => Promise.resolve(locale___nuxt_dsc_locales_fi_FI_json), cache: true },
{ key: "../src/locales/fi-FI.js", load: () => Promise.resolve(locale__service_src_locales_fi_FI_js), cache: true }],
  "en-EU": [{ key: "../../.nuxt/dsc/locales/en-EU.json", load: () => Promise.resolve(locale___nuxt_dsc_locales_en_EU_json), cache: true },
{ key: "../src/locales/en-EU.js", load: () => Promise.resolve(locale__service_src_locales_en_EU_js), cache: true }],
  "sv-SE": [{ key: "../../.nuxt/dsc/locales/sv-SE.json", load: () => Promise.resolve(locale___nuxt_dsc_locales_sv_SE_json), cache: true },
{ key: "../src/locales/sv-SE.js", load: () => Promise.resolve(locale__service_src_locales_sv_SE_js), cache: true }],
  "no-NO": [{ key: "../src/locales/no-NO.js", load: () => Promise.resolve(locale__service_src_locales_no_NO_js), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../src/locales/i18n.config.js?hash=c5e3605c&config=1" /* webpackChunkName: "__src_locales_i18n_config_js_c5e3605c" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./src/locales/i18n.config.js",
  "locales": [
    {
      "code": "fi-FI",
      "name": "Suomi",
      "domain": "https://st1.fi",
      "files": [
        "/.nuxt/dsc/locales/fi-FI.json",
        "/service/src/locales/fi-FI.js"
      ]
    },
    {
      "code": "en-EU",
      "name": "English",
      "domain": "https://st1.com",
      "files": [
        "/.nuxt/dsc/locales/en-EU.json",
        "/service/src/locales/en-EU.js"
      ]
    },
    {
      "code": "sv-SE",
      "name": "Svenska",
      "domain": "https://st1.se",
      "files": [
        "/.nuxt/dsc/locales/sv-SE.json",
        "/service/src/locales/sv-SE.js"
      ]
    },
    {
      "code": "no-NO",
      "domain": "https://st1.no",
      "files": [
        "/service/src/locales/no-NO.js"
      ]
    }
  ],
  "defaultLocale": "fi-FI",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": false,
  "differentDomains": true,
  "baseUrl": "https://st1.fi",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": true,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "sync": true,
  "i18nModules": [
    {
      "locales": [
        {
          "code": "en-EU",
          "name": "English"
        },
        {
          "code": "fi-FI",
          "name": "Suomi"
        },
        {
          "code": "sv-SE",
          "name": "Svenska"
        }
      ],
      "langDir": "../.nuxt/dsc/locales/"
    }
  ]
}

export const normalizedLocales = [
  {
    "code": "fi-FI",
    "name": "Suomi",
    "domain": "https://st1.fi",
    "files": [
      {
        "path": "/.nuxt/dsc/locales/fi-FI.json"
      },
      {
        "path": "/service/src/locales/fi-FI.js"
      }
    ]
  },
  {
    "code": "en-EU",
    "name": "English",
    "domain": "https://st1.com",
    "files": [
      {
        "path": "/.nuxt/dsc/locales/en-EU.json"
      },
      {
        "path": "/service/src/locales/en-EU.js"
      }
    ]
  },
  {
    "code": "sv-SE",
    "name": "Svenska",
    "domain": "https://st1.se",
    "files": [
      {
        "path": "/.nuxt/dsc/locales/sv-SE.json"
      },
      {
        "path": "/service/src/locales/sv-SE.js"
      }
    ]
  },
  {
    "code": "no-NO",
    "domain": "https://st1.no",
    "files": [
      {
        "path": "/service/src/locales/no-NO.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
