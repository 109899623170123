import { defineNuxtPlugin } from '#app';
import { useAnalytics } from '#imports';

export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.hook('dsc:form-builder-submit', async body => {
        const { gtm } = useAnalytics();
        const { $dsc, $i18n } = nuxtApp;
        try {
            await $fetch('/api/v1/form/submit', {
                method: 'POST',
                body,
                headers: { 'x-st1-locale': $i18n.locale.value },
            });
        } catch (e) {
            console.error(e);
            $dsc.msg.error('Could not submit form, try again later');
            const event = {
                event: 'Error Message',
                gtm: {
                    error_status: 500,
                    error_code: 'error',
                    error_text: 'Could not submit form, try again later',
                    error_type: 'exception',
                },
            };
            gtm().trackEvent(event);
            throw e;
        }
    });
});
