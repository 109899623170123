
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46url_93p2UUXkYpgdMeta } from "/service/src/pages/[...url].vue?macro=true";
import { default as component_45stubzFxfuLkpmAMeta } from "/service/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubzFxfuLkpmA } from "/service/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "url___fi-FI",
    path: "/:url(.*)*",
    component: () => import("/service/src/pages/[...url].vue")
  },
  {
    name: "url___en-EU",
    path: "/:url(.*)*",
    component: () => import("/service/src/pages/[...url].vue")
  },
  {
    name: "url___sv-SE",
    path: "/:url(.*)*",
    component: () => import("/service/src/pages/[...url].vue")
  },
  {
    name: "url___no-NO",
    path: "/:url(.*)*",
    component: () => import("/service/src/pages/[...url].vue")
  },
  {
    name: component_45stubzFxfuLkpmAMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubzFxfuLkpmA
  }
]