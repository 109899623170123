export default {
    translateString: 'English string',
    site: {
        title: 'St1 Global',
        quickNav: {
            stationLocator: 'Station Locator',
        },
    },
    back: 'Back',

};
