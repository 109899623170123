import { defineNuxtPlugin } from '#imports';
import { createError } from '#app';

export default defineNuxtPlugin(nuxtApp => {
    function customError(error, _instance, _info) {
        throw createError(error);
    }
    nuxtApp.hook('vue:error', customError);
    nuxtApp.hook('app:error', customError);
    nuxtApp.hook('error', customError);
});
