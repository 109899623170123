export default {
    phoneNumber: 'Telefonnummer:',
    email: 'E-post:',
    open: 'Öppettider',
    kitchen: 'A la Carte',
    lunch: 'Lunch',
    breakfast: 'Morgonmål',
    days: {
        Mon: 'Mån',
        Tue: 'Tis',
        Wed: 'Ons',
        Thu: 'Tors',
        Fri: 'Fre',
        Sat: 'Lör',
        Sun: 'Sön',
    },
    station: 'Stasjon',
    'get-directions': 'Få veibeskrivelse',
    services: {
        evcharging: 'Ladestasjoner',
        norwegianshellcarwash: 'Bilvask Norgesvask',
        carwash: 'Bilvask',
        selfservicecarwash: 'Selvvask',
        truckfacilities: 'Lastebil',
        servicestation: 'Bemannetstasjon',
    },
    'services-heading': 'Tjenester',
    site: {
        title: 'St1 Norge',
        quickNav: {
            stationLocator: 'Finn Stasjon',
        },
    },
    back: 'Tilbake',
};
