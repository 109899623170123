export default {
    phoneNumber: 'Telefonnummer:',
    email: 'E-post:',
    open: 'Öppettider',
    kitchen: 'A la Carte',
    lunch: 'Lunch',
    breakfast: 'Morgonmål',
    days: {
        Mon: 'Mån',
        Tue: 'Tis',
        Wed: 'Ons',
        Thu: 'Tors',
        Fri: 'Fre',
        Sat: 'Lör',
        Sun: 'Sön',
    },
    'get-directions': 'Hitta hit',
    services: {
        post: 'Posti',
        servicefueling: 'Betjäning vid tankning',
        adblueprivatecars: 'AdBlue',
        carmaintenance: 'Bilservice',
        trailerrent: 'Biluthyrning',
        matkahuolto: 'Matkahuolto',
        automatedposti: 'Posti automat',
        automatedmatkahuolto: 'MH automat',
        veneasema: 'Marine station',
        st1waymobiilitankkaus: 'St1-way mobiltankning',
        nestekaasu: 'Flytande gas',
        sauna: 'Bastu för truckdrivers',
        shower: 'Dusch för truckdrivers',
        truckfuelingpoint: 'Truck service',
        atm: 'Bankautomat',
        selfservicecarwash: 'Självbetjäningsbiltvätt',
        carwash: 'Biltvättar',
        perfectcarwash: 'Perfect autopesu',
        carwash24h: 'Biltvätt 24h',
        perfectcarwash24hours: 'Perfect biltvätt 24h',
        selfservicepaymentterminal: 'Expresskassa',
        veikkaus: 'Veikkaus',
        burgerking: 'Burger King',
        kotipizza: 'Kotipizza',
        lunch: 'Lunch',
        weekendlunch: 'Helglunch',
        bottlerec: 'Flaskretur automat',
        cafeteria: 'Cafe',
        driverspremium: 'Drivers Premium',
        goldenrax: 'Rax Pizza Buffet',
        othershop: 'Butik',
        vanrental: 'Skåpbilsuthyrning',
        restaurantnotconcept: 'Resaurang',
        drivers: 'Drivers',
        meetingroom: 'Mötesrum',
        freewifi: 'Gratis Wifi',
        pizza: 'Pizza',
        breakfastbuffet: 'Frukostbuffét',
        boutique: 'Butik',
        coffee: 'Café',
        fastfood: 'Snabbmat',
        toilet: 'Toalett',
        welcomein: 'Välkommen In',
        ploq: 'Ploq',
        laddstation: 'Laddstation e-bil',
        ecolabelcarwash: 'Svanemerket Biltvätt',
        dirtcarwash: 'Dirt Carwash',
        caravanparking: 'Caravan Parking',
        alwaysonoffer: 'Alltid tillgängligt',
        helmisimpukkaexpress: 'Helmisimpukka Express',
        schenker: 'Schenker-paketit',
        budbee: 'Budbee-paketit',
        evcharging: 'Elbilsladdning',
        food: 'Mat',
        servicestation: 'Bemannad station',
    },
    'services-heading': 'Tjänster',
    site: {
        title: 'St1 Sverige',
        quickNav: {
            stationLocator: 'Hitta station',
        },
    },
    back: 'Tillbaka',
};
