import revive_payload_client_4sVQNw7RlN from "/service/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/service/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/service/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/service/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/service/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/service/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/service/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/service/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/service/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/service/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/service/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_Ug908EZNww from "/service/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import switch_locale_path_ssr_5csfIgkrBP from "/service/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/service/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import filters_6LZ8DAXSu2 from "/service/node_modules/@lamiaoy/st1-ds-components-3/dist/runtime/components/StationLocator/plugins/filters.mjs";
import gmap_vue_W05IrZlozH from "/service/node_modules/@lamiaoy/st1-ds-components-3/dist/runtime/plugins/gmap-vue.mjs";
import flicking_fsCHcmyqQZ from "/service/node_modules/@lamiaoy/st1-ds-components-3/dist/runtime/plugins/flicking.mjs";
import analytics_cEx4YRSVxc from "/service/node_modules/@lamiaoy/st1-ds-components-3/dist/runtime/plugins/analytics.mjs";
import cms_2A8in6Y0CU from "/service/node_modules/@lamiaoy/st1-ds-components-3/dist/runtime/plugins/cms.mjs";
import breakpoints_Dgju6WflvL from "/service/node_modules/@lamiaoy/st1-ds-components-3/dist/runtime/plugins/breakpoints.mjs";
import dsc_Xn7BX03DqQ from "/service/node_modules/@lamiaoy/st1-ds-components-3/dist/runtime/plugins/dsc.mjs";
import error_handler_XwGrXj5ExL from "/service/src/plugins/error-handler.js";
import form_builder_78J7wQti36 from "/service/src/plugins/form-builder.js";
import paths_5M38PBaEGT from "/service/src/plugins/paths.js";
import statusMessage_URz8IYETq4 from "/service/src/plugins/statusMessage.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_Ug908EZNww,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  filters_6LZ8DAXSu2,
  gmap_vue_W05IrZlozH,
  flicking_fsCHcmyqQZ,
  analytics_cEx4YRSVxc,
  cms_2A8in6Y0CU,
  breakpoints_Dgju6WflvL,
  dsc_Xn7BX03DqQ,
  error_handler_XwGrXj5ExL,
  form_builder_78J7wQti36,
  paths_5M38PBaEGT,
  statusMessage_URz8IYETq4
]