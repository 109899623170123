import { useUserStore } from '~dsc/stores/cms/user';
import { useNuxtApp, defineNuxtRouteMiddleware, useRequestHeaders } from '#imports';

export default defineNuxtRouteMiddleware(async () => {
    const { $i18n, $paths } = useNuxtApp();
    const { cookie } = useRequestHeaders(['cookie']);

    const { headers } = await $fetch.raw('/api/v1/auth', {
        headers: {
            'x-st1-locale': $i18n.locale.value,
            cookie,
        },
    });

    if (headers.get('x-user-status') === 'AUTHORIZED') {
        try {
            const updatedCookies = headers.getSetCookie();
            let cookies;

            if (import.meta.server) {
                if (updatedCookies.length > 0) {
                    cookies = updatedCookies.map(cookie => cookie.split(';')[0]).join('; ');
                } else {
                    cookies = cookie;
                }
            }

            const userData = await $fetch(`/proxy/${$paths.getEndpoint('customer')}`, {
                headers: {
                    cookie: cookies,
                },
            });

            if (userData) {
                useUserStore().$patch({
                    user: {
                        firstname: userData.firstName,
                        lastname: userData.lastName,
                        email: userData.emailToken,
                        phone: userData.phoneNumber,
                        telephonePrefix: useUserStore().parsePhone('prefix', userData.phoneNumber),
                        telephoneNumber: useUserStore().parsePhone('number', userData.phoneNumber),
                    },
                    loggedIn: true,
                });
            } else {
                useUserStore().resetUser();
            }
        } catch (e) {
            console.warn('User not found for session on b2c-my-pages-view-v2/v1/customer/details');
        }
    }
});
